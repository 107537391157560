import {ColorModeContext} from "../../Theme/Theme";
import React from "react";
import {Box, Stack, Typography} from "@mui/material";
import colors from "../../Theme/LayoutTheme";
import {UseIsMobile, UseIsScreenXS} from "../../../Utilities/ScreenSize";
import Logo2 from '../../Assets/Logo2.png';
import {contactInfoStyles, containerStyles, logoContainerStyles, logoStyles} from "./FooterStyles";

const ContactInfo = ({label, value}) => (
    <Stack direction='row' spacing={1} sx={contactInfoStyles()} justifyContent='center'
           alignSelf={{xs: 'start', md: 'center'}} flexWrap='wrap'>
        <Typography variant={UseIsScreenXS() ? "subtitle2" : 'body2'} color="primary.textBright" fontWeight={400}>
            {label}:
        </Typography>
        <Typography variant={UseIsScreenXS() ? "subtitle2" : 'body2'} color="primary.textBright" fontWeight={400}>
            {value}
        </Typography>
    </Stack>
);

export default function Footer() {
    const colorMode = React.useContext(ColorModeContext);
    const theme = colors(colorMode?.getActualMode()!);

    const contactDetails = [
        {id: 'phone', label: 'טלפון', value: '9913*'},
        {id: 'fax', label: 'פקס', value: '08-6220800'},
        {id: 'mailcell', label: 'ת.ד', value: '346'},
        {id: 'address', label: 'כתובת', value: 'רח\' הרקפת 6, שדרות'},
        {id: 'PostalCode', label: 'מיקוד', value: '8701301'},
    ];

    return (
        <Box sx={containerStyles(theme)}>
            <Box sx={logoContainerStyles(theme)}>
                <img
                    src={Logo2}
                    width={UseIsMobile() ? "100%" : "70%"}
                    height={UseIsMobile() ? "70%" : "100%"}
                    alt="Logo"
                    style={logoStyles() as React.CSSProperties}
                />
            </Box>

            <Stack
                padding={2}
                direction={{xs: 'column', md: 'row'}}
                justifyContent='center'
                spacing={{xs: 1, sm: 2, md: 5, xl: 7}}
                bgcolor='background.footer'
                minHeight={{xs: "250px", sm: '300px', md: '95px', lg: "73px"}}
                width='100%'
            >
                {contactDetails.map(({id, label, value}) => (
                    <ContactInfo key={id} label={label} value={value}/>
                ))}
            </Stack>
        </Box>
    );
}