import { Theme } from '@mui/material';

export const jobItemStyles = (theme: Theme, colorMode: any) => ({
    jobCard: {
        width: '100%',
        borderRadius: '0px 0px 10px 10px',
        boxShadow: '0px 3px 10px',
        color: 'primary.myBoxShadow',
        borderColor: 'background.JobTitle2',
        textAlign: 'center',
    },
    priorityBadge: {
        borderRadius: 0.5,
        height: 'fit-content',
        paddingRight: 0.5,
        paddingLeft: 0.5,
        paddingTop: 0.2,
        paddingBottom: 0.2,
        backgroundColor: colorMode?.getActualMode()! === 'bright-contrast' ? '#ddbb7f' : 'primary.filterButton',
        transform: 'rotate(-20deg)',
    },
    priorityText: {
        color: colorMode?.getActualMode()! === 'dark-contrast' ? '#000000' : '#FFFFFF'
    },
    titleBox: {
        mt: { xs: '38px', sm: '28px', md: '48px' },
        backgroundColor: 'background.JobTitle2',
        direction: 'column',
        paddingTop: 0.5,
        paddingBottom: 0.5,
        justifyContent: 'end',
        borderRadius: '0px 31px 31px 0px',
        opacity: 1,
        marginTop: '48px',
        ml: { xs: 0, md: '-10px', lg: '-15px' },
        mr: { xs: '50px', md: '100px' },
        width: 'fit-content',
        maxWidth: '90%'
    },
    titleText: {
        textAlign: 'left',
        fontWeight: 600,
        letterSpacing: '0px',
        color: 'primary.JobTitle2',
        opacity: 1,
        maxWidth: '80%',
        ml: { xs: '20px', md: '32px', lg: '39px' },
        mr: '40px',
    },
    descriptionContainer: {
        width: '100%',
        paddingLeft: { xs: 2.5, md: '26px' },
        paddingRight: '26px',
        paddingTop: '10px',
        mt: 1
    },
    descriptionText: {
        textAlign: 'left',
        letterSpacing: '0px',
        color: 'primary.myCardText',
        opacity: 1,
        ml: 0.25
    },
    detailsIcon: {
        color: colorMode?.getActualMode()! === 'bright-contrast' ? '#CC4584' : 'secondary.jobDetails'
    },
    detailsLabel: {
        textAlign: 'left',
        letterSpacing: '0px',
        color: colorMode?.getActualMode()! === 'bright-contrast' ? '#CC4584' : 'secondary.jobDetails',
        opacity: 1,
    },
    detailsValue: {
        textAlign: 'left',
        letterSpacing: '0px',
        color: 'primary.myCardText',
        opacity: 1,
    },
    jobLink: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        textDecoration: 'none',
        '&:hover > #arrow': {
            transition: 'all .3s cubic-bezier(.34,1.61,.7,1.3)',
            transform: 'translateX(5px)',
            color: 'secondary.visitJobButton'
        },
        '&:hover #element1': {
            color: 'secondary.visitJobButton',
        },
        '&:hover #element2': {
            backgroundColor: 'secondary.visitJobButton',
        },
        '&:hover': {
            color: 'secondary.visitJobButton'
        },
    },
    linkText: {
        textAlign: 'left',
        color: 'primary.visitJobButton',
        opacity: 1,
        textDecorationLine: 'underline',
        letterSpacing: '0px',
        mb: 0,
        '&:hover': { color: 'secondary.visitJobButton' }
    },
    arrowIcon: {
        color: 'primary.visitJobButton',
        fontSize: 21.5,
        alignSelf: 'center',
        mt: 0.3
    }
});