import React from "react";
import {ColorModeContext, FontContext, useMode} from "./Theme";
import {ThemeProvider} from "@mui/material/styles";

export default function DrushimThemeProvider({children}) {
    const [theme, colorMode, fontMode] = useMode();

    return (
        <FontContext.Provider value={fontMode}>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    {children}
                </ThemeProvider>
            </ColorModeContext.Provider>
        </FontContext.Provider>
    );
}