import React from "react";
import {Box, Button, Icon, Typography} from "@mui/material";
import {ReactComponent as DownArrowSVG} from "../../../../Assets/DownArrow.svg";
import {boxStyles, buttonStyles, iconStyles, typographyStyles} from "./ScrollToStyles";

export default function ScrollTo(props: { element: React.RefObject<HTMLParagraphElement>, text: string }) {
    const {element, text} = props;

    const scroll = () => {
        if (element.current) {
            element.current.scrollIntoView({behavior: 'smooth'});
        }
    }

    return (
        <Box sx={boxStyles}>
            <Button
                sx={buttonStyles}
                onClick={() => scroll()}
            >
                <Typography
                    variant='h4'
                    color={"primary.textBright"}
                    sx={typographyStyles}
                >
                    {text}
                </Typography>

                <Icon
                    sx={iconStyles}
                    component={DownArrowSVG}
                />
            </Button>
        </Box>
    );

}