import React from 'react';
import {components, DropdownIndicatorProps} from 'react-select';
import {KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';
import {ColorModeContext} from "../../../../Theme/Theme";
import useStyles from "./DropdownIndicatorStyles";

export default function DropdownIndicator(props: DropdownIndicatorProps<any, true>) {
    const {selectProps} = props;
    const colorMode = React.useContext(ColorModeContext);
    const styles = useStyles(colorMode?.getActualMode());

    return (
        <>
            {selectProps.menuIsOpen ? (
                <components.DropdownIndicator {...props}>
                    <KeyboardArrowUp
                        fontSize="small"
                        sx={styles.arrowIcon}
                    />
                </components.DropdownIndicator>
            ) : (
                <components.DropdownIndicator {...props}>
                    <KeyboardArrowDown
                        fontSize="small"
                        sx={styles.arrowIcon}
                    />
                </components.DropdownIndicator>
            )}
        </>
    );
};