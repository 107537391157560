export const headerStyles = (theme: any) => ({
    desktopImage: {
        maxWidth: '100%',
        width: '100%',
        filter:
            theme !== 'light' && theme !== 'dark-contrast' && theme !== 'bright-contrast'
                ? 'grayscale(1)'
                : theme === 'bright-contrast'
                    ? 'brightness(0.5)'
                    : theme === 'dark-contrast'
                        ? 'brightness(0.8)'
                        : 'grayscale(0)'
    },
    mobileImage: {
        maxWidth: '100%',
        display: 'flex',
        filter:
            theme !== 'light' && theme !== 'dark-contrast' && theme !== 'bright-contrast'
                ? 'grayscale(1)'
                : theme === 'bright-contrast'
                    ? 'brightness(0.7)'
                    : theme === 'dark-contrast'
                        ? 'brightness(0.5)'
                        : 'grayscale(0)'
    },
    desktopTitleContainer: {
        mt: {md: -6, xl: -10},
        position: 'relative',
        overflow: 'visible',
        height: '120px',
        maxWidth: '600px',
        mr: 'auto',
        ml: 'auto',
        background:
            theme === 'light'
                ? '#FFFFFF'
                : theme === 'dark-contrast'
                    ? '#000000'
                    : theme === 'bright-contrast'
                        ? '#FFFFFF'
                        : '#FFFFFF',
        boxShadow: '0px 3px 6px',
        color: 'secondary.drushimTitleContainer',
        borderRadius: '15px',
        opacity: 1,
        display: {xs: 'none', md: 'flex'},
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center'
    },
    titleRow: {
        filter: theme === 'bright-contrast'
            ? 'brightness(0.7)'
            : 'brightness(1)',
    },
    primaryTitle: {
        color: 'primary.drushimTitle',
        alignSelf: 'center',
    },
    secondaryTitle: {
        color: 'secondary.drushimTitle',
        alignSelf: 'center',
    },
    subtitle: {
        mt: 1,
        color: 'secondary.drushimTitle',
        filter: theme === 'bright-contrast'
            ? 'brightness(0.7)'
            : 'brightness(1)',
    },
    mobileTitleContainer: {
        display: {xs: 'block', md: 'none'},
        paddingRight: 3,
        paddingLeft: 3,
        paddingTop: 2,
        filter: theme === 'bright-contrast'
            ? 'brightness(0.7)'
            : 'brightness(1)',
    },
    mobileSubtitle: {
        mt: 1,
        textAlign: 'center',
        color: 'secondary.drushimTitle',
    }
});