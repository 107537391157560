import React from 'react';
import {Grid, Box, Stack, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {getFilterStyle, useStyles} from "./NoItemsStyles";
import {ColorModeContext} from "../../../../Theme/Theme"; // Import your ColorModeContext
import Logo from "../../../../Assets/logo.png";

export default function NoItems() {
    const theme = useTheme();
    const colorMode = React.useContext(ColorModeContext);
    const styles = useStyles(theme);
    const gridItemStyles = {...styles.gridItem} as React.CSSProperties;

    return (
        <Grid item xs={12} style={{...gridItemStyles, filter: getFilterStyle(colorMode?.getActualMode()!)}}>
            <Stack direction="column" spacing={3} width="100%">
                <Box display="flex" justifyContent="center">
                    <Stack
                        direction="column"
                        width="100%"
                        spacing={4}
                        sx={styles.contentStack}
                    >
                        <Box sx={styles.logoContainer}>
                            <img style={styles.logo} src={Logo} alt="Logo"/>
                        </Box>
                        <Stack direction="column" spacing={1}>
                            <Typography color="primary.drushimTitle" variant="h2" textAlign="center">
                                אנו מתנצלים,
                            </Typography>
                            <Typography variant="body2" color="secondary.drushimTitle" textAlign="center">
                                לא נמצאו משרות זמינות כרגע...
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Grid>
    );
}