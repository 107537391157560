import {Box, Fab, Fade} from "@mui/material";
import {KeyboardArrowUpRounded} from "@mui/icons-material";
import React from "react";
import {ColorModeContext} from "../../../../Theme/Theme";
import {useScrollTopStyles} from "./ScrollTopStyles";

export default function ScrollTop() {
    const [showTopBtn, setShowTopBtn] = React.useState(false);
    const colorMode = React.useContext(ColorModeContext);
    const styles = useScrollTopStyles(colorMode?.getActualMode()!);

    const goToTop = () => {
        window.scrollTo({
            top: 500,
            behavior: "smooth",
        });
    };

    React.useEffect(() => {
        const handleScroll = () => setShowTopBtn(window.scrollY > 1500);
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <Fade timeout={450} in={showTopBtn} style={styles.fadeStyle}>
            <Box id="floatingButton" sx={styles.floatingButtonStyle}>
                <Fab
                    sx={styles.fabStyle}
                    aria-label="add"
                    onClick={goToTop}
                >
                    <KeyboardArrowUpRounded sx={styles.arrowStyle}/>
                </Fab>
            </Box>
        </Fade>
    );
}