export const useStyles = (theme) => ({
    gridItem: {
        backgroundColor: 'inherit',
        overflow: 'initial',
        position: 'relative',
        transition: '0.3s',
        alignItems: 'center',
        borderRadius: '16px',
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingRight: theme.spacing(2),
    },
    logo: {
        height: 100,
    },
    contentStack: {
        borderTop: '5px solid',
        borderRadius: '0px 0px 10px 10px',
        boxShadow: '0px 3px 10px',
        color: theme.palette.primary.myBoxShadow,
        borderColor: theme.palette.background.JobTitle2,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
});

export const getFilterStyle = (theme) => {
    if (theme === 'light' || theme === 'dark-contrast' || theme === 'bright-contrast') {
        return theme === 'bright-contrast' ? 'brightness(0.7)' : 'brightness(1)';
    }
    return 'grayscale(1)';
};