import {SxProps, Theme} from '@mui/material';

export const useStyles = (colorMode) => {
    const container: SxProps<Theme> = {
        borderBottom:
            colorMode === 'light'
                ? '1px solid #CCCCCC'
                : colorMode === 'dark-contrast'
                    ? '1px solid #505050'
                    : '1px solid #CCCCCC',
    };

    const label: SxProps<Theme> = {
        fontFamily: 'Rubik',
        fontSize: '15px',
        letterSpacing: '0px',
        color: colorMode === 'light' ? '#6E6E6E' : 'inherit',
        opacity: 1,
    };

    return {
        container,
        label,
    };
};