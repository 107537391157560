import {Box, Stack, Typography} from "@mui/material";
import NavBarImage from "../../../../Assets/navbarImage.png";
import NavBarImage_mobile from "../../../../Assets/navbarImage_mobile.png";
import React from "react";
import {ColorModeContext} from "../../../../Theme/Theme";
import {headerStyles} from "./HeaderStyles";

export default function Header() {
    const colorMode = React.useContext(ColorModeContext);
    const styles = headerStyles(colorMode?.getActualMode()!);

    const TitleContent = ({variant}: { variant: 'h1' | 'h2' }) => (
        <Stack direction='row' justifyContent='center' width='100%'>
            <Typography variant={variant} sx={styles.secondaryTitle}>
                גְּ
            </Typography>
            <Typography variant={variant} sx={styles.primaryTitle}>
                וָו
            </Typography>
            <Typography variant={variant} sx={styles.secondaryTitle}>
                נִים
            </Typography>
            <Typography variant={variant} sx={styles.primaryTitle}>
                ,
            </Typography>
            <Typography variant={variant} sx={{...styles.primaryTitle, ml: 1}}>
                לעבוד עם הנשמה
            </Typography>
        </Stack>
    );

    return (
        <Box id='NavbarImage_and_TitlePaper'>
            <Box id='NavBarImage_container_desktop' display={{xs: 'none', sm: 'flex'}}>
                <img
                    src={NavBarImage}
                    alt="NavBarImage"
                    style={styles.desktopImage}
                />
            </Box>

            <Box id='NavBarImage_container_mobile' display={{xs: 'flex', sm: 'none'}}>
                <img
                    src={NavBarImage_mobile}
                    alt="NavBarImage_mobile"
                    style={styles.mobileImage}
                />
            </Box>

            <Stack id="title_container_desktop" sx={styles.desktopTitleContainer}>
                <Stack direction='row' justifyContent='center' width='100%' sx={styles.titleRow}>
                    <TitleContent variant="h1"/>
                </Stack>

                <Typography variant='body2' sx={styles.subtitle}>
                    בואו לעבוד עם עמותת גוונים 9913*
                </Typography>
            </Stack>

            <Box id='title_container_mobile' sx={styles.mobileTitleContainer}>
                <TitleContent variant="h2"/>
                <Typography variant='body2' sx={styles.mobileSubtitle}>
                    בואו לעבוד עם עמותת גוונים 9913*
                </Typography>
            </Box>
        </Box>
    );
}
