import {Breakpoint, useMediaQuery, useTheme} from "@mui/material";
import {Theme} from "@mui/material/styles";

export const UseScreenSelectors = () => {
    const theme: Theme = useTheme();
    const keys: readonly Breakpoint[] = [...theme.breakpoints.keys].reverse();
    return (
        keys.reduce((output: Breakpoint | null, key: Breakpoint) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key));
            return !output && matches ? key : output;
        }, null) || 'xs'
    );
}

export const UseIsScreenXS = () => UseScreenSelectors() === 'xs';

export const UseIsMobile = () => {
    const screenSize = UseScreenSelectors();
    return screenSize === 'xs' || screenSize === 'sm' || screenSize === 'md';
};

export const UseIsDesktop = () => !UseIsMobile();