import React from 'react';
import {components} from 'react-select';

export default function Input(props, selectInput) {
    return (
        <>
            {selectInput.length === 0 ? (
                <components.Input
                    autoFocus={props.selectProps.menuIsOpen}
                    {...props}
                >
                    {props.children}
                </components.Input>
            ) : (
                <components.Input
                    autoFocus={props.selectProps.menuIsOpen}
                    {...props}
                >
                    {props.children}
                </components.Input>
            )}
        </>
    );
}