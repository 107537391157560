import React from 'react';
import {default as ReactSelect, InputAction} from 'react-select';
import {LinearProgress} from '@mui/material';
import DropdownIndicator from "./Components/DropdownIndicator/DropdownIndicator";
import NoOptionsMessage from "./Components/Options/NoOptions";
import Input from "./Components/Input";
import {OptionType} from "./Option.type";
import Options from "./Components/Options/Options";
import {ColorModeContext} from "../../Theme/Theme";
import {createCustomStyles} from "./CustomMultiSelectStyles";

const MultiSelect = (props: any) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const colorMode = React.useContext(ColorModeContext);
    const [loading, setLoading] = React.useState(true);
    const [selectInput, setSelectInput] = React.useState<string>("");
    const isAllSelected = React.useRef<boolean>(false);
    const selectAllLabel = React.useRef<string>("Select all");
    const allOption = {value: "*", label: selectAllLabel.current};

    const filterOptions = (options: OptionType[], input: string) =>
        options?.filter(({label}: OptionType) => label.toLowerCase().includes(input.toLowerCase()));

    let filteredOptions = filterOptions(props.options, selectInput);
    let filteredSelectedOptions = filterOptions(props.value, selectInput);

    const comparator = (lhs: OptionType, rhs: OptionType) => (lhs.value as number) - (rhs.value as number);

    const customFilterOption = ({value, label}: OptionType, input: string, filteredOptions: OptionType[]) =>
        (value !== "*" && label.toLowerCase().includes(input.toLowerCase())) || (value === "*" && filteredOptions?.length > 0);

    const onInputChange = (inputValue: string, event: { action: InputAction }) => {
        if (event.action === "input-change") {
            setSelectInput(inputValue);
        } else if (event.action === "menu-close" && selectInput !== "") {
            setSelectInput("");
        }
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        if ((e.key === " " || e.key === "Enter") && !selectInput) {
            e.preventDefault();
        }
    };

    const handleSelectAll = () => {
        const newOptions = props.options.filter(({label}: OptionType) => {
            const isLabelMatching = label.toLowerCase().includes(selectInput?.toLowerCase());
            const isNotAlreadySelected = !(props.value ?? []).some((opt: OptionType) => opt.label === label);
            return isLabelMatching && isNotAlreadySelected;
        });
        return props.onChange([...(props.value ?? []), ...newOptions].sort(comparator));
    };

    const isPartialSelection = (selected: OptionType[]) =>
        selected.length > 0 && selected[selected.length - 1].value !== allOption.value &&
        JSON.stringify(selected.sort(comparator)) !== JSON.stringify(filteredOptions);

    const handleDeselection = () =>
        props.onChange(props.value?.filter(({label}: OptionType) =>
            !label.toLowerCase().includes(selectInput?.toLowerCase())) ?? []);

    const handleChange = (selected: OptionType[]) => {
        const isAllOptionSelected = selected.length > 0 && selected[selected.length - 1].value === allOption.value;
        const areAllFilteredOptionsSelected = JSON.stringify(filteredOptions) === JSON.stringify(selected.sort(comparator));
        const isNewSelectionMade = selected.length > 0 && !isAllSelected.current;

        if (isNewSelectionMade && (isAllOptionSelected || areAllFilteredOptionsSelected)) {
            return handleSelectAll();
        } else if (isPartialSelection(selected)) {
            return props.onChange(selected);
        } else {
            return handleDeselection();
        }
    };

    const customStyles = createCustomStyles(colorMode?.getActualMode()!);

    if (props.isSelectAll && props.options.length !== 0) {
        isAllSelected.current = JSON.stringify(filteredSelectedOptions) === JSON.stringify(filteredOptions);
        if (filteredSelectedOptions?.length > 0) {
            if (filteredSelectedOptions?.length === filteredOptions?.length) {
                selectAllLabel.current = `(${filteredOptions.length}) נבחרו`;
            } else {
                selectAllLabel.current = `${filteredSelectedOptions.length} / ${filteredOptions.length} נבחרו`;
            }
        } else {
            selectAllLabel.current = "בחר הכל";
        }
        allOption.label = selectAllLabel.current;

        return (
            <ReactSelect
                {...props}
                inputValue={selectInput}
                onInputChange={onInputChange}
                onKeyDown={onKeyDown}
                options={[allOption, ...props.options]}
                onChange={handleChange}
                placeholder={open ? 'חיפוש..' : 'בחרו מיקום'}
                components={{
                    Option: Options,
                    Input: (props) => Input(props, selectInput),
                    ...props.components,
                    DropdownIndicator,
                    NoOptionsMessage,
                }}
                filterOption={customFilterOption}
                menuPlacement={props.menuPlacement ?? "auto"}
                styles={customStyles}
                isMulti
                closeMenuOnSelect={false}
                tabSelectsValue={false}
                backspaceRemovesValue={false}
                hideSelectedOptions={false}
                blurInputOnSelect={false}
                onMenuOpen={() => setOpen(true)}
                onMenuClose={() => setOpen(false)}
            />
        );
    }

    return (
        <>
            {loading ? (
                <LinearProgress/>
            ) : (
                <ReactSelect
                    {...props}
                    inputValue={selectInput}
                    onInputChange={onInputChange}
                    filterOption={customFilterOption}
                    components={{
                        Input: (props) => Input(props, selectInput),
                        ...props.components,
                    }}
                    menuPlacement={props.menuPlacement ?? "auto"}
                    onKeyDown={onKeyDown}
                    tabSelectsValue={false}
                    hideSelectedOptions={true}
                    backspaceRemovesValue={false}
                    blurInputOnSelect={true}
                />
            )}
        </>
    );
};

export default MultiSelect;