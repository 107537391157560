import {Box, Stack} from "@mui/material";
import React from "react";
import {components} from 'react-select';
import {ColorModeContext} from "../../../../Theme/Theme";
import {useStyles} from "./OptionsStyles";

export default function Options(props: any) {
    const colorMode = React.useContext(ColorModeContext);
    const styles = useStyles(colorMode?.getActualMode());

    const turnIndeterminate = (input) => input.indeterminate = true;

    return (
        <Box sx={styles.container}>
            <components.Option {...props}>
                <Stack direction="row" margin={0.5} spacing={1}>
                    {props.value === "*" &&
                    !props.isAllSelected &&
                    props.filteredSelectedOptions?.length > 0 ? (
                        <input
                            key={props.value}
                            type="checkbox"
                            ref={turnIndeterminate}
                        />
                    ) : (
                        <input
                            key={props.value}
                            type="checkbox"
                            checked={props.isSelected || props.isAllSelected}
                            onChange={() => {
                            }}
                        />
                    )}
                    <Box sx={styles.label}>
                        {props.label}
                    </Box>
                </Stack>
            </components.Option>
        </Box>
    );
}