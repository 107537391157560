import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton
} from "@mui/material";
import {Close} from "@mui/icons-material";
import {box, dialogActions, dialogTitle, iconButton} from "./DialogStyles";

// TODO: Make none of the options mandatory
export default function CustomDialog(props: {
    open,
    setOpen,
    handleFailureClose,
    handleSuccessClose,
    title: string,
    description: string,
    okayLabel: string,
    closeLabel: string | null
}) {
    const {open, setOpen, handleFailureClose, handleSuccessClose, title, description, okayLabel, closeLabel} = props;

    const handleClose = (success) => {
        setOpen(false);
        if (success) {
            handleSuccessClose();
        } else {
            handleFailureClose();
        }
    }

    return (
        <React.Fragment>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <Box
                    sx={box()}>
                    <DialogTitle sx={dialogTitle()}>
                        {title}
                    </DialogTitle>

                    <IconButton
                        edge="start"
                        onClick={() => handleClose(false)}
                        aria-label="close"
                        color={"error"}
                        sx={iconButton()}
                    >
                        <Close/>
                    </IconButton>
                </Box>

                <DialogContent>
                    <DialogContentText>
                        {description}
                    </DialogContentText>
                </DialogContent>

                <DialogActions
                    sx={dialogActions()}>
                    <Button onClick={() => handleClose(true)} color={"error"} variant={"contained"}>{okayLabel}</Button>
                    {closeLabel && (
                        <Button onClick={() => handleClose(false)} color={"info"}>{closeLabel}</Button>
                    )}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}