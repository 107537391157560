import { Avatar, Box, Link, Rating, Typography } from "@mui/material";
import Logo from "../../../../Components/Logo/logo.png";
import React from "react";
import { Candidate, getFilteredCandidates } from "../../../../Firebase/FirebaseFunctions/Candidate";
import { AccountBalanceOutlined, AccountBox, Person, Person2, PersonOutline } from "@mui/icons-material";
import MyLoading from "../../../../Components/MyLoading/MyLoading";
import NotesPopup from "../../ViewCandidatesPage/Components/NotesPopup/NotesPopup";
import AreYouSureDialog from "../../ViewCandidatesPage/Components/AreYouSureDialog/AreYouSureDialog";
import { useNavigate } from "react-router-dom";

export default function CandidateCard(props: { candidate: Candidate | undefined | any }) {
    const { candidate } = props;

    const [loading, setLoading] = React.useState<boolean>(true);
    // general rating
    const [generalRating, setGeneralRating] = React.useState<number | null>(0);


    // comments popup handlers
    const [popupOpen, setPopupOpen] = React.useState<boolean>(false);
    const [comments, setComments] = React.useState<string | undefined>("");


    const navigate = useNavigate();
	// are you sure dialog
	const [areYouSureDialogOpen, setAreYouSureDialogOpen] = React.useState<boolean>(false);
	const [areYouSureCallback, setAreYouSureCallback] = React.useState<(() => {})>();
	const [areYouSureDialogMessage, setAreYouSureDialogMessage] = React.useState("");

	const closeAreYouSureDialog = (event, reason) =>
	{
		if ((reason && reason !== "backdropClick") || reason === undefined)
		{
			setAreYouSureDialogOpen(false);
		}
	}

    	// success message
	const [snackBarOpen, setSnackBarOpen] = React.useState(false);
	const snackBarOnClose = () =>
	{
		setSnackBarOpen(false);
	}

    const commentsPopupOpenHandler = () => {
        setComments(candidate?._note);
        setPopupOpen(true);
    };

    const commentsPopupCloseHandler = async (event, reason) => {
        if ((reason && reason !== "backdropClick") || reason === undefined) {
            setPopupOpen(false);
            setLoading(false);
        }
    };


    React.useEffect(() => {
        // pull candidate from firebase
        // getCandidate(candidateId, setCandidateInfo);

        // getJobs(candidateId, setCandidateJobs);
        setLoading(false);
    }, [])

    return (
        <>
            {loading ? (<MyLoading loading={loading} setLoading={setLoading} />)
                :
                (
                    <Box width='100%' display='flex' flexDirection='row' justifyContent='center'>
                        <NotesPopup
                            open={popupOpen}
                            onClose={commentsPopupCloseHandler}
                            candidate={candidate}
                            initialData={comments}
                            setInitialData={setComments}
                            setLoading={setLoading}
                            loading={loading} />
                        <Box flex={{ xs: 1, md: 0.3, xl: 0.4 }} display='flex' flexDirection={{ xs: 'column', md: 'row' }}
                            sx={{
                                border: '1px solid rgba(0, 0, 0, 0.125)',
                                borderRadius: 2,
                                backgroundColor: '#FFFFFF'

                            }}

                            p={{ xs: 0.5, md: 1 }}>
                            <Box
                            //  sx={{ border: '1px solid gray' }}
                            >
                                <Avatar variant='rounded'
                                    sx={{
                                        width: { xs: '100%', md: 150 },
                                        height: { xs: 90, md: '100%' },
                                        padding: { xs: 2, md: 3 },
                                        //  background: '#fafaf8',
                                        background: '#bbcafc',
                                        // background: 'none',
                                        // borderRight: '1px solid #dee2e6',
                                        // '& .MuiAvatar-img': {
                                        //     objectFit: 'contain',
                                        // }

                                    }}
                                >
                                    <Person sx={{
                                        // padding: 1,
                                        width: '100%',
                                        height: '100%',
                                        color: "hsla(0,0%,100%,.7)"
                                    }} />
                                </Avatar>
                            </Box>
                            <Box
                                flex={1}
                                display='flex'
                                flexDirection='column'
                                gap={0.8}
                                // pb={0.5}
                                pr={2}
                                pl={2}
                                pt={1}
                            // sx={{ border: '1px solid gray' }}
                            >
                                <Box gap={10} sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }} display='flex' flexDirection='row' justifyContent='space-between'>
                                    <Typography component={'span'} fontSize='small' fontWeight={600} fontFamily='Rubik'>
                                        שם מלא:
                                    </Typography>

                                    <Typography component={'span'} fontSize='small' fontWeight={500} fontFamily='Rubik' color='GrayText'>
                                        {`${candidate?._firstName!} ${candidate?._lastName!}`}
                                    </Typography>
                                </Box>


                                <Box gap={10} sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }} display='flex' flexDirection='row' justifyContent='space-between'>
                                    <Typography fontSize='small' fontWeight={600} fontFamily='Rubik'>
                                        אימייל:
                                    </Typography>

                                    <Typography fontSize='small' fontWeight={500} fontFamily='Rubik' color='GrayText'>
                                        {candidate?._eMail}
                                    </Typography>
                                </Box>

                                <Box gap={10} sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }} display='flex' flexDirection='row' justifyContent='space-between'>
                                    <Typography fontSize='small' fontWeight={600} fontFamily='Rubik'>
                                        מס' טלפון:
                                    </Typography>

                                    <Typography fontSize='small' fontWeight={500} fontFamily='Rubik' color='GrayText'>
                                        {candidate?._phone!}
                                    </Typography>
                                </Box>

                                <Box gap={10} sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }} display='flex' flexDirection='row' justifyContent='space-between'>
                                    <Typography fontSize='small' fontWeight={600} fontFamily='Rubik'>
                                        דירוג כללי:
                                    </Typography>


                                    <AreYouSureDialog
										open={areYouSureDialogOpen}
										onClose={closeAreYouSureDialog}
										message={areYouSureDialogMessage}
										callback={areYouSureCallback}
										setSnackBarOpen={setSnackBarOpen}
									/>
                                    
                                    <Rating size="small" value={generalRating}
                                        onChange={(event, newValue) => {
                                            {
                                                setAreYouSureDialogOpen(true);
                                                setAreYouSureDialogMessage("פעולה זו תשנה את דרגת ההתאמה הכללית של המועמד");
                                                setAreYouSureCallback((() =>

                                                    () =>
                                                    {
                                                        candidate?.updateGeneralRating(newValue ? newValue : -1);
                                                        setGeneralRating(newValue ? newValue : -1);
                                                        return true;
                                                    }
                                                ))
                                            }}
                                        } />


                                </Box>


                                <Box gap={10} sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }} display='flex' flexDirection='row' justifyContent='space-between'>
                                    <Typography fontSize='small' fontWeight={600} fontFamily='Rubik'>
                                        הערות:
                                    </Typography>

                                    <Typography sx={{ wordBreak: 'break-word' }} fontSize='small' fontWeight={500} fontFamily='Rubik' color='GrayText'>
                                        {candidate?._note == undefined || candidate?._note?.length == 0 ? 'אין' : candidate?._note!}
                                    </Typography>
                                </Box>


                                <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} pt={1.5} pb={0.5}>
                                    <Link 
                                    onClick={() => {navigate("/management/manageCandidates/" + candidate?._id! + "/interviews");}}
                                     href="#" underline="hover" fontSize='small' fontWeight={600} fontFamily='Rubik'>
                                        {'ראיונות'}
                                    </Link>
                                    <Link href="#" 
                                    onClick={() => {navigate("/management/editCandidate", { state: candidate?._id! })}}
                                     underline="hover" fontSize='small' fontWeight={600} fontFamily='Rubik'>
                                        {'עריכת פרטים'}
                                    </Link>
                                    <Link
                                        onClick={commentsPopupOpenHandler}
                                        href="#" underline="hover" fontSize='small' fontWeight={600} fontFamily='Rubik'>
                                        {'הוסף/ערוך הערות'}
                                    </Link>
                                    <Link
                                        onClick={async () => {
                                            setLoading(true);
                                            const cvLink = (await candidate?.getCvUrl()!);
                                            setLoading(false);
                                            window.open(cvLink);
                                        }}
                                        href="#" underline="hover" fontSize='small' fontWeight={600} fontFamily='Rubik'>
                                        {'קו"ח'}
                                    </Link>
                                </Box>
                                {/* <Box gap={10} display='flex' flexDirection='row' justifyContent='space-between'>
                    <Typography fontSize='small' fontWeight={600} fontFamily='Rubik'>
                        נוצרה בתאריך:
                    </Typography>

                    <Typography fontSize='small' fontWeight={500} fontFamily='Rubik' color='GrayText'>
                        {candidate?._firstName!}
                    </Typography>
                </Box> */}
                            </Box>
                        </Box>
                    </Box>
                )
            }
        </>
    );
}
