import React from "react";
import {OptionType} from "../../../../../Components/CustomMultiSelect/Option.type";
import {getOpenRoles} from "../../../../../../Firebase/FirebaseFunctions/Role";
import MultiSelect from "../../../../../Components/CustomMultiSelect/CustomMultiSelect";

export default function RolesMultiSelect(props: { optionSelected: any, setSelected: any }) {
    const {optionSelected, setSelected} = props;
    const [roles, setRoles] = React.useState<OptionType[]>([]);

    const handleChange = (selected: OptionType[]) => setSelected(selected);
    const fetchRoles = async () => {
        let temp = await getOpenRoles();
        return temp?.map((role, index) => ({value: index, label: role?._name}));
    }

    React.useEffect(() => {
        fetchRoles().then((roles) => setRoles(roles));
    }, []);

    return (
        <MultiSelect
            options={roles}
            onChange={handleChange}
            value={optionSelected}
            isSelectAll={true}
            menuPlacement={"bottom"}
        />
    );
}