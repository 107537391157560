export const containerStyles = {
    padding: 2,
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
};

export const stackStyles = {
    paddingTop: 4,
    paddingBottom: 4,
    direction: 'column',
    width: '100%',
    spacing: 4,
    color: 'primary.myBoxShadow',
};

export const logoBoxStyles = {
    display: 'flex',
    justifyContent: 'center',
    paddingRight: 2,
    paddingBottom: 1
};

export const logoImageStyles = {
    height: 100,
};

export const titleTypographyStyles = {
    color: 'primary.drushimTitle',
    textAlign: 'center',
};

export const subtitleTypographyStyles = {
    color: 'secondary.drushimTitle',
    textAlign: 'center',
};