import React from "react";
import {Job} from "../../../../../Firebase/FirebaseFunctions/Job";
import {Box, Typography} from "@mui/material";
import {UseScreenSelectors} from "../../../../../Utilities/ScreenSize";
import JobInfoSummary from "./JobInfoSummary";
import ScrollTo from "../ScrollTo/ScrollTo";

export default function JobInformation(props: {
    job: Job,
    jobApplicationElement: React.RefObject<HTMLParagraphElement>
}) {
    const {job, jobApplicationElement} = props;

    const screenSize = UseScreenSelectors();

    React.useEffect(() => {

    }, [job]);

    return (<>
        {/* Job Number */}
        <Box sx={{display: "flex", flexDirection: "row", width: {xs: "100%", md: "41.71875vw"}}}>

            <Typography variant={screenSize === "xs" ? "subtitle2" : 'h4'}
                        sx={{letterSpacing: 0, color: "secondary.jobDetails"}}>
                משרה מספר:
            </Typography>
            <Typography variant={screenSize === "xs" ? "subtitle2" : 'h4'}
                        sx={{marginLeft: "11px", color: "secondary.descAndReqText"}}>
                {job?._jobNumber}
            </Typography>
        </Box>

        {/* Job Title */}
        <Box
            sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                textAlign: "left",
                width: {xs: "100%", md: "41.71875vw"}
            }}
        >

            <Typography
                sx={{
                    color: "primary.jobTitle",
                }}
                variant={screenSize === 'xs' ? "h6" : "h1"}
            >
                {job?._title}
            </Typography>
        </Box>

        {/* Separator */}
        <Box sx={{
            width: {xs: "100%", md: "36.6vw"},
            backgroundColor: "background.jobTitleSeparator",
            height: 2,
            opacity: 0.2,
            marginTop: "27px"
        }}/>

        <Box>

            {/* Job description, stats and requirements */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: {xs: "column", md: "row"},
                    justifyContent: "stretch",
                    marginTop: {xs: "44px", md: "128px"}
                }}
            >

                {/* Description, requirements, stats and additional info */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "background.box",
                        flex: 100,
                        marginRight: {xs: "0", md: "1rem"},
                    }}
                >
                    {/* description and requirements */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >

                        {/* description */}
                        <Box
                            sx={{
                                flex: 7
                            }}
                        >
                            <Typography variant={screenSize === "xs" ? "subtitle1" : 'h2'}
                                        color={"primary.descAndReqTitle"}>
                                תיאור המשרה:
                            </Typography>

                            <Typography
                                variant={screenSize === "xs" ? "subtitle2" : 'h4'}
                                marginTop={"15px"}
                                sx={{
                                    backgroundColor: "background.boxInner",
                                    color: "secondary.descAndReqText",
                                    width: {xs: "100%", md: "30vw"}
                                }}
                            >
                                {job?._description?.length! >= 1 ?
                                    job?._description[0].split('\n').map((line, index) => {
                                        return (
                                            <React.Fragment key={"jobDescriptionLine" + index}>
                                                {line}
                                                <br/>
                                            </React.Fragment>
                                        )
                                    })
                                    : ""
                                }
                            </Typography>
                        </Box>

                    </Box>
                    {/* requirements */}
                    <Box
                        sx={{
                            backgroundColor: "transparent",
                            flex: 4
                        }}
                    >
                        <Typography variant={screenSize === "xs" ? "subtitle1" : 'h2'} sx={{
                            color: "primary.descAndReqTitle",
                            marginTop: {xs: "44px", md: "73px"}
                        }}>
                            דרישות התפקיד:
                        </Typography>

                        <Typography
                            variant={screenSize === "xs" ? "subtitle2" : 'h4'}
                            marginTop={"15px"}
                            sx={{
                                backgroundColor: "background.boxInner",
                                color: "secondary.descAndReqText",
                                width: {xs: "100%", md: "28vw"}
                            }}
                        >
                            {job?._requirements.split('\n').map((line, index) => {
                                return (
                                    <React.Fragment key={"jobRequirementsLine" + index}>
                                        {line}
                                        <br/>
                                    </React.Fragment>
                                )
                            })}
                        </Typography>
                    </Box>
                </Box>

                {/* Job Details */}
                <JobInfoSummary job={job}/>

            </Box>

            <ScrollTo text={"להגשת מועמדות"} element={jobApplicationElement}/>
        </Box>
    </>);
}
