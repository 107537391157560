import React from 'react';
import {Box, Stack, InputBase} from '@mui/material';
import {SearchRounded} from '@mui/icons-material';
import {ColorModeContext} from "../../../../../Theme/Theme";
import {searchBarStyles} from "./SearchBarStyles";

export default function SearchBar(props: { text: string, setText: any, handleFilter: any }) {
    const {text, setText, handleFilter} = props;
    const colorMode = React.useContext(ColorModeContext)?.getActualMode()!;

    const handleText = (event: any) => setText(event.target.value);
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleFilter(text);
        }
    };

    return (
        <Box component="form" sx={searchBarStyles.form}>
            <Box sx={searchBarStyles.container}>
                <Stack direction='row'>
                    <Box sx={searchBarStyles.iconContainer}>
                        <SearchRounded
                            fontSize='small'
                            sx={searchBarStyles.icon(colorMode)}
                        />
                    </Box>
                    <InputBase
                        sx={searchBarStyles.inputBase(colorMode)}
                        onKeyDown={handleKeyDown}
                        placeholder='כתבו כאן...'
                        inputProps={{'aria-label': 'search'}}
                        value={text}
                        onChange={handleText}
                    />
                </Stack>
                <Box sx={searchBarStyles.underline(colorMode)}/>
            </Box>
        </Box>
    );
}