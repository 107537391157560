import { ArticleOutlined } from '@mui/icons-material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Avatar, Box, Button, Checkbox, Container, Divider, FormControlLabel, FormHelperText, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { exportToExcel } from '../../../../Firebase/FirebaseFunctions/Reports/GlobalFunctions';
import JobsByFilters from '../../../../Firebase/FirebaseFunctions/Reports/JobsFilters';
import { Job, generateJobNumber, getAllRoles, getAllSectors, getFilteredCandidateJobStatuses, getFilteredCandidates, getFilteredJobs } from '../../../../Firebase/FirebaseFunctions/functionIndex';
import { BoxGradientSx, MyPaperSx } from '../../../ManageJobsPage/Components/NewJobPage/NewJobStyle';
import { designReturnButton } from '../../../ManageJobsPage/ManageJobsPageStyle';
import { MyReportStyle, radioStyle } from '../../ReportPageStyle';
import { useEffect } from 'react';
import MyLoading from '../../../../Components/MyLoading/MyLoading';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';

interface typeMyData {
    id: number;
    name: string;
}

export default function JobsForm() {
    const navigate = useNavigate();
    const [roles, setRoles] = React.useState<typeMyData[]>([]);
    const [sectors, setSectors] = React.useState<typeMyData[]>([]);
    const [selectedRole, setSelectedRole] = React.useState<string>('כל התפקידים');
    const [selectedSector, setSelectedSector] = React.useState<string>('כל האשכולות');
    const [openJobs, setOpenJobs] = React.useState<boolean>(true);
    const [highPriority, setHighPriority] = React.useState<boolean>(false);
    const [startOn, setStartOn] = React.useState<boolean>(false);
    const [startDate, setStartDate] = React.useState(dayjs(new Date).set('d', -7));
    const [endDate, setEndDate] = React.useState(dayjs(new Date));
    const [loading, setLoading] = React.useState(false);


    useEffect(() => {
        const fileData = async () => {
            // get sectors 
            let i = 20;
            const sectorsFromDb = await getAllSectors();
            let updatedSectors = [{ id: 10, name: 'כל האשכולות' }];

            updatedSectors = updatedSectors.concat(
                sectorsFromDb.map((sector) => {
                    const sectorObj = { id: i, name: sector._name };
                    i = i + 10
                    return sectorObj;
                })
            );

            setSectors(updatedSectors);
            // get roles
            const rolesFromDb = await getAllRoles();
            i = 20;
            let updatedRoles = [{ id: 10, name: "כל התפקידים" }];
            updatedRoles = updatedRoles.concat(
                rolesFromDb.map((role) => {
                    const roleObj = { id: i, name: role._name };
                    i = i + 10;
                    return roleObj;
                })
            );
            setRoles(updatedRoles);
        };

        fileData();
    }, []);


    const createReport = () => {
        // checking if the user select all the buttons
        const isDateSelected = startDate && endDate;

        if (!selectedRole ||
            !selectedSector ||
            !isDateSelected) {
            // displaying an error message or indicating to the user that the parameters are mandatory
            alert('יש למלא את כל השדות');
            return;
        }
        const formattedStartDate = startDate.toDate();
        const formattedEndDate = endDate.toDate();

        setLoading(true);
        const result = JobsByFilters(selectedRole, selectedSector, openJobs, highPriority, startOn,formattedStartDate, formattedEndDate)
            .then((result) => {
                setLoading(false);
                if (result.length === 0)
                    alert('אין נתונים להצגה');
                else
                    exportToExcel(result, `משרות`);
            })
            .catch((error) => {
                setLoading(false);
                return
            });
    }




    // handls
    function handleChangeRole(event: SelectChangeEvent<string>, child: React.ReactNode): void {
        setSelectedRole(event.target.value);
    }

    // function handleChangeScope(event: SelectChangeEvent<string>, child: React.ReactNode): void {
    //     setScope(event.target.value);
    // }

    const handleChangeSector = (event) => {
        setSelectedSector(event.target.value);
    };

    const handleChangeOpenJobs = (event) => {
        setOpenJobs(event.target.value);
    };

    const handleChangeHighPriority = (event) => {
        setHighPriority(event.target.value);
    };

    const handleChangeStartOn = (event) => {
        setStartOn(event.target.value);
    };

    // const handleApplyPerPlatform = (event) => {
    //     setApplayPlatformUserSelected(event.target.value);
    // }

    // const handleViewPerPlatform = (event) => {
    //     setViewPlatformUserSelected(event.target.value);
    // }
    const handleChangeStartDate = (date) => {
        setStartDate(date);
    };

    const handleChangeEndDate = (date) => {
        setEndDate(date);
    };

    const handleClick = () => {
        navigate("/management/reports");
    };

    return (
                        <Stack flex={1} mb={2}>
                            <Stack ml={1} mr={1} mb={1} direction='column' gap={4}>
                                <Stack m={2} direction='column' gap={1}>
                                    <Box display='flex' flexDirection='row' justifyContent='center'>
                                        <Avatar variant='circular'
                                            sx={{
                                                width: { xs: 70, md: 100 },
                                                height: { xs: 60, md: '100%' },
                                                padding: { xs: 2, md: 3 },
                                                background: '#bbcafc',
                                            }}
                                        >
                                            <ArticleOutlined sx={{
                                                width: '100%',
                                                height: '100%',
                                                color: "hsla(0,0%,100%,.7)"
                                            }} />
                                        </Avatar>
                                    </Box>
                                    <Typography alignSelf='center' sx={{
                                        fontSize: 25,
                                        fontFamily: 'Rubik',
                                        fontWeight: 600,
                                        opacity: 0.8,
                                    }}>
                                        דו"ח משרות
                                    </Typography>
                                </Stack>

                                <Stack direction='row' gap={2}>
                                    <Stack>
                                        <FormControlLabel control={<Checkbox checked={openJobs} onChange={(e) => setOpenJobs(e.target.checked)}/>} label="כלול רק את המשרות הפתוחות" />
                                        <FormHelperText sx={{ mt: '0px' }}>במידה ושדה זה לא יסומן, הדו"ח יכלול גם את המשרות הסגורות</FormHelperText>
                                    </Stack>
                                    <Divider orientation="vertical" flexItem />
                                    <Stack>
                                        <FormControlLabel control={<Checkbox checked={highPriority} onChange={(e) => setHighPriority(e.target.checked)}/>} label="כלול רק את המשרות בעדיפות גבוהה" />
                                        <FormHelperText sx={{ mt: '0px' }}>במידה ושדה זה יסומן, הדו"ח יכלול רק את המשרות אשר מסווגות ב-"עדיפות גבוהה"</FormHelperText>
                                    </Stack>
                                </Stack>


                                <Stack direction='row' gap={2}>
                                    {/* select role */}
                                    {roles?.length > 0 &&
                                        <FormControl fullWidth size="small">
                                            <InputLabel>תפקיד</InputLabel>
                                            <Select
                                                value={selectedRole}
                                                label="תפקיד"
                                                onChange={handleChangeRole}
                                            >
                                                {roles.map((role) => (
                                                    <MenuItem key={role.id} value={role.name}>
                                                        {role.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>משרות תחת תפקיד ספציפי</FormHelperText>
                                        </FormControl>
                                    }

                                    {/* select % scope of job */}
                                    {/* <FormControl fullWidth size="small">
                                        <InputLabel>היקף המשרה</InputLabel>
                                        <Select
                                            value={scope}
                                            label="היקף המשרה"
                                            onChange={handleChangeScope}
                                        >
                                            <MenuItem value={10}>25% - 0%</MenuItem>
                                            <MenuItem value={20}>50% - 25%</MenuItem>
                                            <MenuItem value={30}>75% - 50%</MenuItem>
                                            <MenuItem value={40}>100% - 75%</MenuItem>
                                            <MenuItem value={50}>כל המשרות</MenuItem>
                                        </Select>
                                        <FormHelperText>משרות בעלות היקף משרה ספציפי</FormHelperText>
                                    </FormControl> */}

                                    {/* sector*/}
                                    {sectors?.length > 0 &&
                                        <FormControl fullWidth size="small">
                                            <InputLabel>אשכול</InputLabel>
                                            <Select
                                                value={selectedSector}
                                                label="אשכול"
                                                onChange={handleChangeSector}
                                            >
                                                {sectors.map((sector) => (
                                                    <MenuItem key={sector.id} value={sector.name}>
                                                        {sector.name}
                                                    </MenuItem>
                                                ))}

                                            </Select>
                                            <FormHelperText>משרות תחת אשכול ספציפי</FormHelperText>
                                        </FormControl>
                                    }
                                </Stack>

                                {/* select time */}
                                <Box display='flex' flexDirection='row' justifyContent='space-between'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='he'>
                                        <Stack spacing={2} direction={'row'} justifyContent={'center'}>
                                            <DatePicker
                                                slotProps={{ textField: { size: 'small' } }}
                                                label="מתאריך"
                                                value={startDate}
                                                onChange={handleChangeStartDate}
                                            />
                                            <Typography alignSelf='center' sx={{
                                                fontSize: 15,
                                                fontFamily: 'Rubik',
                                                fontWeight: 600,
                                                letterSpacing: '0px',
                                                opacity: 0.8,
                                            }}>
                                                -
                                            </Typography>
                                            <DatePicker
                                                slotProps={{ textField: { size: 'small' } }}
                                                label="עד תאריך"
                                                value={endDate}
                                                onChange={handleChangeEndDate}
                                            />
                                        </Stack>
                                    </LocalizationProvider>
                                    <Box display='flex' flexDirection='column-reverse'>
                                        {/* create report */}
                                        <LoadingButton
                                            color="info"
                                            variant="contained"
                                            onClick={() => createReport()}
                                            loading={loading}
                                        >
                                            הנפק דו"ח</LoadingButton>
                                    </Box>

                                </Box>

                            </Stack>
                        </Stack >
    );
}
