import {createTheme} from "@mui/material/styles";
import React from "react";

const MAX_FONT_SIZE = 35;
const MIN_FONT_SIZE = 12;

function colorLightMode() {
    return {
        primary: {
            main: "#000000",
            textBright: "#FFFFFF",
            faded: "#FEFEFE",
            jobTitle: "#053B7A",
            descAndReqTitle: "#5BA1AA",
            addRecommenderButton: "#91A749",
            visitJobButton: '#5BA1AA',
            JobTitle2: "#FFFFFF",
            myBoxShadow: '#00000029',
            myCardText: '#767676',
            divider: '#D3D3D3',
            filterBar: '#FAFAFA',
            filterButton: '#91A749',
            jobScopeButton: '#EDEDED',
            drushimTitle: '#41C2F0',
            drushimTitleContainer: '#FFFFFF'
        },

        secondary: {
            main: "#03368a",
            half: "#184fa8",
            dark: "#041a3d",
            descAndReqText: "#767676",
            jobDetails: "#AC2F69",
            labelText: "#053B7A",
            addRecommendersTextHover: "#7BC3CC",
            addRecommenderButtonHover: "#BFD37E",
            visitJobButton: '#72C3CE',
            filterButton: '#B4CC64',
            jobScopeButton: '#D5D5D5',
            drushimTitle: '#053B7A',
            drushimTitleContainer: '#00000029'

        },
        background: {
            globalBackground: "#FFFFFF",
            jobDetailsBackground: "#FFFFFF",
            main: "#ffffff",
            default: "#ffffff",
            box: "#ffffff",
            boxInner: "#ffffff",
            jobDetails: "#FFFFFF",
            JobDetailsText: "#053B7A",
            jobTitleSeparator: "#2E3552",
            candidateDetailsTextField: "#F0F0F0",
            cvButton: "#41C2F0",
            cvButtonHover: "#77CFEF",
            submitButtonHover: "#2768B4",
            recommendersBox: "rgba(91, 161, 170, 0.14)",
            footer: "#002652",
            successHover: "#009900",
            JobTitle2: "#053B7A",
        },
        error: {
            main: "#FF0000"
        },
        success: {
            main: "#00AA00"
        }
    }
}

function colorBrightContrastMode() {
    return {
        primary: {
            main: "#000000",
            textBright: "#000000",
            faded: "#333333",
            jobTitle: "#010c18",
            descAndReqTitle: "#122022",
            myCardText: '#767676',
            addRecommenderButton: "#1d210f",
            myBoxShadow: '#00000029',
            jobScopeButton: '#EDEDED',
            JobTitle2: "#000000",
            drushimTitle: '#41C2F0',
            divider: '#D5D5D5',
            filterBar: '#FAFAFA',
            filterButton: '#DDBB7F',
            visitJobButton: '#86bac0'
        },

        secondary: {
            main: "#010b1c",
            half: "#051022",
            dark: "#01050c",
            descAndReqText: "#181818",
            jobDetails: "#220915",
            labelText: "#010c18",
            addRecommendersTextHover: "#122c2f",
            addRecommenderButtonHover: "#2a3211",
            drushimTitleContainer: '#00000029',
            jobScopeButton: '#D5D5D5',
            visitJobButton: '#afd1d5',
            filterButton: '#EDDBBB',
            drushimTitle: '#053B7A',


        },
        background: {
            globalBackground: "#FFFFFF",
            jobDetailsBackground: "#FFFFFF",
            main: "#ffffff",
            default: "#ffffff",
            box: "#ffffff",
            boxInner: "#ffffff",
            jobDetails: "#FFFFFF",
            JobDetailsText: "#b5d6fc",
            jobTitleSeparator: "#ced2e4",
            candidateDetailsTextField: "#fcfcfc",
            cvButton: "#d9f3fc",
            cvButtonHover: "#e4f5fc",
            submitButtonHover: "#cfe0f5",
            recommendersBox: "rgba(91, 161, 170, 0.14)",
            footer: "#a9d1ff",
            successHover: "#009900",
            JobTitle2: "#a9d1ff",
        },
        error: {
            main: "#FF0000"
        },
        success: {
            main: "#00FF00"
        }
    }
}

function colorDarkContrastMode() {
    return {
        primary: {
            main: "#FFFFFF",
            textBright: "#b2d0ec",
            faded: "#ffffff",
            jobTitle: "#6cadf9",
            descAndReqTitle: "#bdd9dd",
            addRecommenderButton: "#d4deb4",
            visitJobButton: '#c3c6aa',
            JobTitle2: "#000000",
            myBoxShadow: '#7c7c7c',
            myCardText: '#FFFFFF',
            divider: '#555650',
            filterBar: '#191919',
            filterDivider: '#3f3f3f',
            filterButton: '#bfc87a',
            jobScopeButton: '#2d2d2d',
            drushimTitle: '#b2d0ec',
            drushimTitleContainer: '#000000',
        },

        secondary: {
            main: "#6ea4fc",
            half: "#90b4ef",
            dark: "#5894f4",
            descAndReqText: "#c8c8c8",
            jobDetails: "#e6a4c2",
            labelText: "#6cadf9",
            addRecommendersTextHover: "#cae7eb",
            addRecommenderButtonHover: "#e5edcb",
            visitJobButton: '#e8ead7',
            filterButton: '#e1e8ab',
            jobScopeButton: '#424242',
            drushimTitle: '#366294',
            drushimTitleContainer: '#666666'


        },
        background: {
            globalBackground: "#000000",
            jobDetailsBackground: "#000000",
            main: "#000000",
            default: "#000000",
            box: "#000000",
            boxInner: "#000000",
            jobDetails: "#000000",
            JobDetailsText: "#010c18",
            jobTitleSeparator: "#090b10",
            candidateDetailsTextField: "#303030",
            cvButton: "#042b39",
            cvButtonHover: "#083140",
            submitButtonHover: "#081524",
            recommendersBox: "rgba(91, 161, 170, 0.14)",
            footer: "#000810",
            successHover: "#009900",
            JobTitle2: "#b2d0ec",
        },
        error: {
            main: "#FF0000"
        },
        success: {
            main: "#00FF00"
        }
    }
}

function colorBlackWhiteMode() {
    return {
        primary: {
            main: colorToGrayscale("#000000"),
            textBright: colorToGrayscale("#FFFFFF"),
            faded: colorToGrayscale("#FEFEFE"),
            jobTitle: colorToGrayscale("#053B7A"),
            descAndReqTitle: colorToGrayscale("#5BA1AA"),
            addRecommenderButton: colorToGrayscale("#91A749"),
            visitJobButton: colorToGrayscale("#5BA1AA"),
            jobScopeButton: '#EDEDED',
            JobTitle2: colorToGrayscale("#FFFFFF"),
            filterButton: '#323232',
            filterBar: '#FAFAFA',
            drushimTitle: '#b2b2b2',
            divider: '#D3D3D3',
        },

        secondary: {
            main: colorToGrayscale("#03368a"),
            half: colorToGrayscale("#184fa8"),
            dark: colorToGrayscale("#041a3d"),
            descAndReqText: colorToGrayscale("#767676"),
            jobDetails: colorToGrayscale("#AC2F69"),
            labelText: colorToGrayscale("#053B7A"),
            addRecommendersTextHover: colorToGrayscale("#7BC3CC"),
            addRecommenderButtonHover: colorToGrayscale("#BFD37E"),
            filterButton: '#5a5a5a',
            drushimTitle: '#5b5b5b',
            jobScopeButton: '#D5D5D5',
            visitJobButton: '#666666',

        },
        background: {
            globalBackground: colorToGrayscale("#FFFFFF"),
            jobDetailsBackground: colorToGrayscale("#FFFFFF"),
            main: colorToGrayscale("#ffffff"),
            default: colorToGrayscale("#ffffff"),
            box: colorToGrayscale("#ffffff"),
            boxInner: colorToGrayscale("#ffffff"),
            jobDetails: colorToGrayscale("#FFFFFF"),
            JobDetailsText: colorToGrayscale("#053B7A"),
            jobTitleSeparator: colorToGrayscale("#2E3552"),
            candidateDetailsTextField: colorToGrayscale("#F0F0F0"),
            cvButton: colorToGrayscale("#41C2F0"),
            cvButtonHover: colorToGrayscale("#77CFEF"),
            submitButtonHover: colorToGrayscale("#2768B4"),
            recommendersBox: colorToGrayscale("rgba(91, 161, 170, 0.14)"),
            footer: colorToGrayscale("#002652"),
            successHover: colorToGrayscale("#009900"),
            JobTitle2: colorToGrayscale("#053B7A"),
        },
        error: {
            main: colorToGrayscale("#FF0000")
        },
        success: {
            main: colorToGrayscale("#00FF00")
        }
    }
}

function colorTokens(mode) {
    const colorModes = {
        light: colorLightMode,
        "bright-contrast": colorBrightContrastMode,
        "dark-contrast": colorDarkContrastMode,
        "black-white": colorBlackWhiteMode
    };

    return (colorModes[mode] || colorModes["black-white"])();
}

function themeSettings(mode, actualMode, fontSize, fontFamily) {
    const colors = colorTokens(actualMode);
    const primaryColors = {
        main: colors.primary.main!,
        textBright: colors.primary.textBright!,
        faded: colors.primary.faded!,
        jobTitle: colors.primary.jobTitle!,
        descAndReqTitle: colors.primary.descAndReqTitle!,
        addRecommenderButton: colors.primary.addRecommenderButton!,
        visitJobButton: colors.primary.visitJobButton!,
        JobTitle2: colors.primary?.JobTitle2,
        myBoxShadow: colors.primary?.myBoxShadow,
        myCardText: colors.primary?.myCardText,
        divider: colors.primary?.divider,
        filterBar: colors.primary?.filterBar,
        filterDivider: colors.primary?.filterDivider,
        filterButton: colors.primary?.filterButton,
        jobScopeButton: colors.primary?.jobScopeButton,
        drushimTitle: colors.primary?.drushimTitle,
        drushimTitleContainer: colors.primary?.drushimTitleContainer,
    };

    const secondaryColors = {
        main: colors.secondary?.main!,
        half: colors.secondary?.half!,
        dark: colors.secondary?.dark!,
        descAndReqText: colors.secondary?.descAndReqText!,
        jobDetails: colors.secondary?.jobDetails!,
        labelText: colors.secondary?.labelText!,
        addRecommendersTextHover: colors.secondary?.addRecommendersTextHover!,
        addRecommenderButtonHover: colors.secondary?.addRecommenderButtonHover,
        visitJobButton: colors.secondary.visitJobButton!,
        filterButton: colors.secondary?.filterButton,
        jobScopeButton: colors.secondary?.jobScopeButton,
        drushimTitle: colors.secondary?.drushimTitle,
        drushimTitleContainer: colors.secondary?.drushimTitleContainer,
    };

    const backgroundColors = {
        main: colors.background?.globalBackground,
        default: colors.background?.globalBackground,
        box: colors.background?.globalBackground,
        boxInner: colors.background?.globalBackground,
        jobDetails: colors.background?.jobDetails,
        JobDetailsText: colors.background?.JobDetailsText,
        jobTitleSeparator: colors.background?.jobTitleSeparator,
        candidateDetailsTextField: colors.background?.candidateDetailsTextField,
        cvButton: colors.background?.cvButton,
        cvButtonHover: colors.background?.cvButtonHover,
        submitButtonHover: colors.background?.submitButtonHover,
        recommendersBox: colors.background?.recommendersBox,
        footer: colors.background?.footer,
        successHover: colors.background?.successHover,
        JobTitle2: colors.background?.JobTitle2,
    };

    const headingStyles = (sizeIncrement, weight = "Medium") => ({
        fontWeight: weight,
        fontFamily,
        fontSize: fontSize + sizeIncrement,
    });

    return {
        palette: {
            mode,
            primary: primaryColors,
            secondary: secondaryColors,
            background: backgroundColors,
            error: {main: colors.error?.main!},
            success: {main: colors.success?.main!},
        },
        typography: {
            fontFamily,
            fontWeight: "Normal",
            fontSize,
            h1: headingStyles(16),
            h2: headingStyles(10),
            h3: headingStyles(5, "Normal"),
            h4: headingStyles(2, "Normal"),
            h5: headingStyles(-4, "Normal"),
            h6: headingStyles(6),
            subtitle1: headingStyles(0),
            subtitle2: headingStyles(-2, "Normal"),
        },
    };
};

// context for color mode
export const ColorModeContext = React.createContext({
    toggleColorMode: (mode) => {
    },
    getActualMode: () => {
    }
});

export const FontContext = React.createContext({
    increaseFontSize: (increaseBy) => {
    },
    decreaseFontSize: (decreaseBy) => {
    },
    changeFontSize: (fontSize) => {
    },
    changeFontFamily: (fontFamily) => {
    },
});

export function useMode() {
    // default is light
    const [mode] = React.useState("light");
    const [fontSize, setFontSize] = React.useState(20);
    const [fontFamily, setFontFamily] = React.useState("Rubik");

    const [actualMode, setActualMode] = React.useState("light");

    const colorMode = React.useMemo(() =>
        ({
            toggleColorMode: (mode) => setActualMode(mode),
            getActualMode: () => actualMode
        }), [actualMode]);


    const fontMode = React.useMemo(() =>
        ({
            increaseFontSize: (increaseBy) => setFontSize(fontSize + increaseBy > MAX_FONT_SIZE ? fontSize : fontSize + increaseBy),
            decreaseFontSize: (decreaseBy) => setFontSize(fontSize - decreaseBy < MIN_FONT_SIZE ? fontSize : fontSize - decreaseBy),
            changeFontFamily: (fontFamily) => setFontFamily(fontFamily),
            changeFontSize: (fontSize) => setFontSize(fontSize)
        }), [fontSize, fontFamily]);

    const theme = React.useMemo(() => createTheme(themeSettings(mode, actualMode, fontSize, fontFamily)), [mode, actualMode, fontSize, fontFamily]);

    const results: any[] = []
    results.push(theme);
    results.push(colorMode);
    results.push(fontMode)

    return results;
}

// helper function for black and white theme
const colorToGrayscale = (color) => {
    const hex = color.replace('#', '');
    const [r, g, b] = [0, 2, 4].map(start => parseInt(hex.substring(start, start + 2), 16));

    // Luminance formula and convert to hex
    const grayscale = Math.round(0.299 * r + 0.587 * g + 0.114 * b).toString(16).padStart(2, '0');
    return `#${grayscale.repeat(3)}`;
};