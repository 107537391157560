import React from "react";
import {ColorModeContext, FontContext} from "../../Theme/Theme";
import {
    CustomDrawer,
    DEFAULT_FONT_FAMILY,
    DEFAULT_FONT_SIZE,
    READABLE_FONT_FAMILY,
    StyledButton
} from "./AccessibilityStyles";
import {
    Contrast,
    FontDownload,
    LightMode,
    Nightlight,
    Replay,
    ZoomInRounded,
    ZoomOutRounded
} from "@mui/icons-material";
import {Box, Divider, List, ListItem, ListItemButton, ListItemIcon, Typography} from "@mui/material";
import AccessibilityIcon from '../../Assets/Accessibility.png';

export default function Accessibility() {
    const [open, setOpen] = React.useState(false);
    const colorMode = React.useContext(ColorModeContext);
    const fontMode = React.useContext(FontContext);
    const [fontFamily, setFontFamily] = React.useState(DEFAULT_FONT_FAMILY);

    const toggleDrawer = (open) => setOpen(open);

    const handleResetAccessibility = () => {
        fontMode.changeFontFamily(DEFAULT_FONT_FAMILY);
        fontMode.changeFontSize(DEFAULT_FONT_SIZE);
        colorMode.toggleColorMode("light");
    };

    const accessibilityOptions = [
        {
            icon: <ZoomOutRounded/>,
            text: 'הקטנת גודל גופן',
            onClick: (fontMode?, fontFamily?, setFontFamily?, colorMode?) => fontMode.decreaseFontSize(2)
        },
        {
            icon: <ZoomInRounded/>,
            text: 'הגדלת גודל גופן',
            onClick: (fontMode?, fontFamily?, setFontFamily?, colorMode?) => fontMode.increaseFontSize(2)
        },
        {
            icon: <Nightlight/>,
            text: 'ניגודיות כהה',
            onClick: (fontMode?, fontFamily?, setFontFamily?, colorMode?) => colorMode.toggleColorMode("dark-contrast"),
            backgroundColor: "#0022AA22"
        },
        {
            icon: <LightMode/>,
            text: 'ניגודיות בהירה',
            onClick: (fontMode?, fontFamily?, setFontFamily?, colorMode?) => colorMode.toggleColorMode("bright-contrast")
        },
        {
            icon: <Contrast/>,
            text: 'שחור-לבן',
            onClick: (fontMode?, fontFamily?, setFontFamily?, colorMode?) => colorMode.toggleColorMode("white-and-dark"),
            backgroundColor: "#EEEEEE"
        },
        {
            icon: <FontDownload/>,
            text: 'פונט קריא',
            onClick: (fontMode?, fontFamily?, setFontFamily?, colorMode?) => {
                const nextFontFamily = fontFamily === DEFAULT_FONT_FAMILY ? READABLE_FONT_FAMILY : DEFAULT_FONT_FAMILY;
                fontMode.changeFontFamily(nextFontFamily);
                setFontFamily(nextFontFamily);
            }
        }
    ];

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "end",
                    position: "fixed",
                    top: "467px",
                    right: "0",
                    zIndex: "10"
                }}
            >
                <StyledButton
                    variant="contained"
                    sx={{
                        width: "120px",
                        backgroundColor: "secondary.jobDetails",
                        "&:hover": {
                            backgroundColor: "secondary.jobDetails"
                        },
                        left: "20px",
                        borderBottomLeftRadius: "56px",
                        borderTopLeftRadius: "56px"
                    }}
                    onClick={() => toggleDrawer(true)}
                >
                    <img src={AccessibilityIcon} alt="Accessibility"
                         style={{height: "63px", width: "63px", marginLeft: 32}}/>
                </StyledButton>
            </Box>

            <CustomDrawer anchor="left" open={open} onClose={() => toggleDrawer(false)}>
                <Box
                    sx={{width: 250}}
                    role="presentation"
                    onKeyDown={() => toggleDrawer(false)}
                >
                    <List>
                        {accessibilityOptions.map((option, index) => (
                            <ListItem key={index} disablePadding sx={{backgroundColor: option.backgroundColor}}>
                                <ListItemButton
                                    onClick={() => option.onClick(fontMode, fontFamily, setFontFamily, colorMode)}>
                                    <ListItemIcon>
                                        {option.icon}
                                    </ListItemIcon>
                                    <Typography variant="h4">{option.text}</Typography>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider/>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={handleResetAccessibility}>
                                <ListItemIcon>
                                    <Replay/>
                                </ListItemIcon>
                                <Typography variant="h4">איפוס נגישות</Typography>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </CustomDrawer>
        </React.Fragment>
    );
}
