import React, {useCallback} from "react";
import {Box, Button, Icon, Input, TextField, Typography} from "@mui/material";
import {UseScreenSelectors} from "../../../../../../Utilities/ScreenSize";

import {ReactComponent as DownArrowSVG} from "../../../../../Assets/DownArrow.svg";
import {ReactComponent as UpArrowSVG} from "../../../../../Assets/UpArrow.svg";
import {Recomendation} from "../../../../../../Firebase/FirebaseFunctions/Recomendation";
import {DeleteForeverOutlined, DeleteOutlined, ErrorOutlineRounded} from "@mui/icons-material";
import CustomDialog from "../../../../../Components/Dialog/Dialog";

import {ReactComponent as AttachFileSVG} from "../../../../../Assets/AttachFile.svg";
import {ReactComponent as PlusIconSVG} from "../../../../../Assets/Plus.svg";
import {isEmailValid, isFileValid, isPhoneValid} from "../Utilities/regex";

const MAX_RECOMMENDERS = 3;

export default function RecommendersForm(props: { validateTrigger, onValidationComplete }) {
    const {validateTrigger, onValidationComplete} = props;

    const [numRecommenders, setNumRecommenders] = React.useState(0);
    const [recommendersList, setRecommendersList] = React.useState<Array<[Recomendation | null, File | null]>>();
    const [recommendersErrors, setRecommendersErrors] = React.useState<boolean[][]>(
        [
            [false, false, false],
            [false, false, false],
            [false, false, false]
        ]
    );
    const [recommendersListOpen, setRecommendersListOpen] = React.useState(false);
    const recommenderFileInputRefs = React.useRef<HTMLInputElement[]>([]);
    const screenSize = UseScreenSelectors();

    // New state for CustomDialog
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogIndex, setDialogIndex] = React.useState(-1);
    const [dialogRecommenderName, setDialogRecommenderName] = React.useState("");

    const updateRecommendersListAtIndex = function (newRecommendation: Recomendation | null, newFile: File | null, index: number) {
        setRecommendersList(prevList => {
            const newList = [...prevList!];
            if (newList.length > 0) {
                newList[index] = [newRecommendation, newFile];
            }
            return newList;
        });
    }

    const handleDeleteRecommender = (index: number) => {
        setDialogIndex(index);
        setDialogOpen(true);
        setDialogRecommenderName(recommendersList![index][0]?._fullName || "");
    };

    const handleDialogSuccessClose = () => {
        if (dialogIndex !== -1) {
            const newList = [...recommendersList!];
            newList[dialogIndex] = [null, null];
            setRecommendersList(newList);
            setNumRecommenders(prevNum => prevNum - 1);
        }
    };


    React.useEffect(() => {
        setRecommendersList(Array.from({length: MAX_RECOMMENDERS}, () => [null, null] as [Recomendation | null, File | null]));
        setNumRecommenders(0);
    }, []);

    const validateFields = useCallback(() => {
        let isValid = true;
        let updatedErrors = recommendersErrors;

        recommendersList?.forEach((recommender, index) => {
            const [recommenderInfo, file] = recommender;

            // Skip validation if both fields are empty
            if (!recommenderInfo && !file) {
                return;
            }

            const emailValid = isEmailValid(recommenderInfo?._eMail || "");
            const phoneValid = isPhoneValid(recommenderInfo?._phone || "");
            const fileValid = isFileValid(file);

            // Update errors for the current recommender
            if (!emailValid || !phoneValid || !fileValid) {
                updatedErrors = updatedErrors.map((error, errorIndex) =>
                    errorIndex === index ? [!phoneValid, !emailValid, !fileValid] : error
                );
                isValid = false;
            }
        });

        setRecommendersErrors(updatedErrors);
        return isValid;
    }, [recommendersErrors, recommendersList]);

    React.useEffect(() => {
        if (validateTrigger) {
            const valid = validateFields();
            if (!valid) {
                setRecommendersListOpen(true);
            }
            onValidationComplete(valid, recommendersList);
        }
    }, [validateTrigger, onValidationComplete, recommendersList, validateFields]);

    return (
        <Box
            sx={{
                display: "flex",
                alignSelf: "center",
                flexDirection: "column",
                alignItems: {xs: "center", md: "start"},
                width: "100%",
                marginTop: "134px",
            }}
        >

            {/* show or hide recommenders button */}
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <Button disableRipple onClick={() => {
                    setRecommendersListOpen(!recommendersListOpen)
                }} sx={{
                    "&:hover": {
                        backgroundColor: "background.main",
                        '& .addRecommendersText': {
                            color: 'secondary.addRecommendersTextHover', // Change the color to your desired hover color
                        },
                        '& .addRecommendersText2': {
                            color: 'secondary.addRecommendersTextHover', // Change the color to your desired hover color
                            opacity: "0.68"
                        },
                        '& .addRecommendersIcon1': {
                            color: 'secondary.addRecommendersTextHover', // Change the color to your desired hover color
                        },
                        '& .addRecommendersIcon2': {
                            color: 'secondary.addRecommendersTextHover', // Change the color to your desired hover color
                        },

                    }
                }}>
                    <Typography variant={screenSize === "xs" ? "subtitle2" : 'h2'}
                                color={"primary.descAndReqTitle"} className='addRecommendersText'>
                        הוספת ממליצים:
                    </Typography>
                    <Typography variant={screenSize === "xs" ? "subtitle2" : 'h2'}
                                color={"primary.descAndReqTitle"}
                                sx={{marginLeft: {xs: "4px", md: "12px"}, opacity: 0.68}}
                                className='addRecommendersText2'>
                        (אופציונאלי)
                    </Typography>
                    {recommendersListOpen ?
                        <Icon sx={{
                            height: "30px",
                            width: "40px",
                            color: "primary.descAndReqTitle",
                            marginLeft: {xs: "2px", md: "12px"}
                        }}
                              className='addRecommendersIcon1'
                              component={DownArrowSVG}
                        /> :

                        <Icon sx={{
                            height: "30px",
                            width: "40px",
                            color: "primary.descAndReqTitle",
                            marginLeft: {xs: "2px", md: "12px"}
                        }}
                              className='addRecommendersIcon1'
                              component={UpArrowSVG}
                        />
                    }

                </Button>
            </Box>

            {/* List of recommenders */}
            <Box sx={{
                backgroundColor: "background.recommendersBox",
                borderRadius: "4px",
                flexDirection: "column",
                paddingTop: "36px",
                paddingBottom: "36px",
                paddingRight: {xs: "0px", md: "23px"},
                alignItems: "center",
                marginTop: "30px",
                width: "100%",

                display: recommendersListOpen ? "flex" : "none"
            }}
            >
                {recommendersList?.map((recommender, index) => {
                    if (recommender[0] !== null) {
                        return (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    marginBottom: index >= MAX_RECOMMENDERS - 1 ? "0" : "43px",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                                key={index + "recommendersBox"}
                            >

                                {/* delete recommender button */}
                                <Button
                                    disableRipple
                                    sx={{
                                        alignSelf: "end",
                                        marginBottom: "27px",
                                        height: "fit-content",
                                        display: {xs: "none", md: "flex"},
                                        "&:hover": {
                                            backgroundColor: "transparent",
                                            color: "error.main"
                                        }
                                    }}
                                    onClick={() => handleDeleteRecommender(index)}
                                >
                                    <DeleteOutlined sx={{}}/>
                                </Button>
                                {/* are you sure you want to delete recommender dialog */}
                                <CustomDialog
                                    open={dialogOpen}
                                    setOpen={setDialogOpen}
                                    handleFailureClose={() => setDialogOpen(false)}
                                    handleSuccessClose={handleDialogSuccessClose}
                                    title="מחיקת ממליץ"
                                    description={`האם אתה בטוח שברצונך למחוק ${dialogRecommenderName ? 'את ' + dialogRecommenderName : 'ממליץ'}?`}
                                    okayLabel="כן, מחק"
                                    closeLabel="ביטול"
                                />

                                {/* Recommender */}
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",

                                        paddingTop: "0",
                                        paddingBottom: "1rem",
                                    }}>

                                    {/* name + phone + email + buttons*/}
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: {xs: "column", md: "row"},
                                        }}
                                    >
                                        {/* mobile delete + attach file buttons */}
                                        <Box
                                            sx={{
                                                alignSelf: "end",
                                                display: {xs: "flex", md: "none"},
                                                flexDirection: "row",
                                                justifyContent: "space-between"
                                            }}
                                        >

                                            {/* Remove recommender button */}
                                            <Button onClick={() => handleDeleteRecommender(index)}>
                                                <DeleteForeverOutlined/>
                                            </Button>
                                        </Box>

                                        {/* Recommender name */}
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginRight: "20px",
                                                marginLeft: {xs: "20px", md: 0},
                                                marginTop: {xs: "20px", md: "0"}
                                            }}
                                        >
                                            <Typography
                                                variant={screenSize === "xs" ? "subtitle1" : 'h3'}
                                                sx={{marginBottom: "15px"}}
                                                color={"primary.jobTitle"}
                                            >
                                                שם מלא:
                                            </Typography>
                                            <TextField
                                                placeholder='שם מלא'
                                                variant='outlined'
                                                sx={{
                                                    backgroundColor: "background.main",
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: recommendersErrors[index][0] ? 'error.main' : "secondary.labelText",
                                                            border: "0px solid black"
                                                        }
                                                    },
                                                    '& .MuiInputBase-input': {
                                                        height: '71px', // Adjust the height value as needed
                                                        paddingTop: "0",
                                                        paddingBottom: "0"
                                                    },
                                                }}
                                                onChange={(event) => {
                                                    const currentRecommender = recommendersList[index][0];
                                                    updateRecommendersListAtIndex(
                                                        new Recomendation(event.target.value,
                                                            currentRecommender?._phone!,
                                                            currentRecommender?._eMail!
                                                        ),
                                                        null,
                                                        index
                                                    );
                                                }}
                                            />
                                        </Box>

                                        {/* Recommender phone */}
                                        <Box
                                            sx={{
                                                marginRight: "20px",
                                                marginLeft: {xs: "20px", md: 0},
                                                marginTop: {xs: "20px", md: "0"}
                                            }}>
                                            <Typography
                                                variant='h3'
                                                color={"secondary.labelText"}
                                                sx={{marginBottom: "15px"}}
                                            >
                                                טלפון:
                                            </Typography>
                                            <TextField
                                                placeholder='טלפון'
                                                variant='outlined'
                                                sx={{
                                                    backgroundColor: "background.main",
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: recommendersErrors[index][0] ? 'error.main' : "secondary.labelText",
                                                            border: "0px solid black"
                                                        }
                                                    },
                                                    '& .MuiInputBase-input': {
                                                        height: '71px', // Adjust the height value as needed
                                                        paddingTop: "0",
                                                        paddingBottom: "0"
                                                    },
                                                }}
                                                onChange={(event) => {
                                                    const currentRecommender = recommendersList[index][0];
                                                    updateRecommendersListAtIndex(
                                                        new Recomendation(currentRecommender?._fullName!,
                                                            event.target.value,
                                                            currentRecommender?._eMail!
                                                        ),
                                                        null,
                                                        index
                                                    );

                                                    // remove error message
                                                    setRecommendersErrors(
                                                        recommendersErrors.map((recommenderError, ind) => {
                                                            if (index === ind) {
                                                                return [false, recommenderError[1], recommenderError[2]];
                                                            } else {
                                                                return [recommenderError[0], recommenderError[1], recommenderError[2]];
                                                            }
                                                        })
                                                    );
                                                }}
                                            />
                                            <Box sx={{
                                                display: recommendersErrors[index][0] ? "flex" : "none",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                position: "absolute"
                                            }}>
                                                <ErrorOutlineRounded sx={{color: "error.main"}}/>

                                                <Typography variant='h4' color={"error.main"}>
                                                    שדה זה שגוי
                                                </Typography>
                                            </Box>
                                        </Box>
                                        {/* Recommender email */}
                                        <Box sx={{
                                            marginRight: "20px",
                                            marginLeft: {xs: "20px", md: 0},
                                            marginTop: {xs: "20px", md: "0"}
                                        }}>
                                            <Typography
                                                variant='h3'
                                                color={"secondary.labelText"}
                                                sx={{marginBottom: "15px"}}
                                            >
                                                אימייל:
                                            </Typography>
                                            <TextField
                                                placeholder='אימייל'
                                                variant='outlined'
                                                sx={{
                                                    backgroundColor: "background.main",
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: recommendersErrors[index][1] ? 'error.main' : "secondary.labelText",
                                                            border: "0px solid black"
                                                        }
                                                    },
                                                    '& .MuiInputBase-input': {
                                                        height: '71px', // Adjust the height value as needed
                                                        paddingTop: "0",
                                                        paddingBottom: "0"
                                                    },
                                                }}
                                                onChange={(event) => {
                                                    const currentRecommender = recommendersList[index][0];
                                                    updateRecommendersListAtIndex(
                                                        new Recomendation(currentRecommender?._fullName!,
                                                            currentRecommender?._phone!,
                                                            event.target.value
                                                        ),
                                                        null,
                                                        index
                                                    );

                                                    // remove error message
                                                    setRecommendersErrors(
                                                        recommendersErrors.map((recommenderError, ind) => {
                                                            if (index === ind) {
                                                                return [recommenderError[0], false, recommenderError[2]];
                                                            } else {
                                                                return [recommenderError[0], recommenderError[1], recommenderError[2]];
                                                            }
                                                        })
                                                    );
                                                }}
                                            />
                                            <Box sx={{
                                                display: recommendersErrors[index][1] ? "flex" : "none",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                position: "absolute"
                                            }}>
                                                <ErrorOutlineRounded sx={{color: "error.main"}}/>

                                                <Typography variant='h4' color={"error.main"}>
                                                    שדה זה שגוי
                                                </Typography>
                                            </Box>
                                        </Box>
                                        {/* Attach recommender file button */}
                                        <Box sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignSelf: {xs: "center", md: "end"},
                                            marginTop: "40px"
                                        }}>
                                            {/* display filename to the user */}
                                            <Box sx={{
                                                position: "absolute",
                                                marginTop: "-20px",
                                                marginLeft: "20px"
                                            }}>
                                                <Typography variant='h5' sx={{alignSelf: "center"}}>
                                                    {recommendersList[index][1] ? "קובץ שצורף: " : ""}
                                                    {recommendersList[index][1]?.name.length! > 12 ? '...' : ''}
                                                    {recommendersList[index][1] ? recommendersList[index][1]?.name.slice(0, 12) : ""}
                                                </Typography>
                                            </Box>
                                            <Box sx={{
                                                display: recommendersErrors[index][2] ? "flex" : "none",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                position: "absolute",
                                                marginTop: "72px",
                                                marginLeft: "10px"
                                            }}>
                                                <ErrorOutlineRounded sx={{color: "error.main"}}/>

                                                <Typography variant='h4' color={"error.main"}>
                                                    שדה זה שגוי
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignSelf: "end"
                                                }}
                                            >
                                                {/* TODO: pdf files only  */}
                                                {/* PC add recommender file input */}
                                                <Input
                                                    type="file"
                                                    inputRef={(input) => (recommenderFileInputRefs.current[index] = input)}
                                                    style={{display: 'none'}}
                                                    inputProps={{
                                                        accept: "application/pdf"
                                                    }}
                                                    onChange={(event) => {
                                                        const inputElement = event.target as HTMLInputElement;
                                                        const files = inputElement.files;
                                                        if (files && files.length > 0 && files[0] && files[0].type === "application/pdf") {
                                                            updateRecommendersListAtIndex(recommendersList[index][0], files[0], index);
                                                        }
                                                        if (files && files.length > 0 && files[0] && files[0].size <= 5 * 1024 * 1024 && files[0].type === "application/pdf") {
                                                            setRecommendersErrors(
                                                                recommendersErrors.map((recommenderError, ind) => {
                                                                    if (index === ind) {
                                                                        return [recommenderError[0], recommenderError[1], false];
                                                                    } else {
                                                                        return [recommenderError[0], recommenderError[1], recommenderError[2]];
                                                                    }
                                                                })
                                                            );
                                                        }
                                                    }}
                                                />
                                                <Button
                                                    disableRipple
                                                    sx={{
                                                        borderRadius: "36px",
                                                        paddingTop: "21px",
                                                        paddingBottom: "21px",
                                                        backgroundColor: "background.cvButton",
                                                        "&:hover": {
                                                            backgroundColor: "background.cvButtonHover"
                                                        }
                                                    }}
                                                    onClick={() => {
                                                        // trigger input onChange
                                                        if (recommenderFileInputRefs.current[index]) {
                                                            recommenderFileInputRefs.current[index]?.click()
                                                        }
                                                    }}

                                                >
                                                    <Icon
                                                        sx={{
                                                            height: "21px",
                                                            width: "19px",
                                                            color: "primary.textBright",
                                                            marginRight: "9px",
                                                            marginLeft: "30px"
                                                        }}
                                                        component={AttachFileSVG}
                                                    />
                                                    <Box
                                                        sx={{
                                                            flexDirection: "column",
                                                            paddingRight: "30px"
                                                        }}
                                                    >
                                                        <Typography
                                                            variant='h3'
                                                            sx={{
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                color: "primary.textBright"
                                                            }}>
                                                            צירוף קובץ
                                                        </Typography>


                                                    </Box>
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        );
                    }
                })}

                {/* Add recommender button */}
                <Box
                    sx={{
                        width: "100%",
                        display: numRecommenders >= MAX_RECOMMENDERS ? "none" : "block"
                    }}>

                    <Button
                        disableRipple
                        sx={{
                            minWidth: "200px",
                            backgroundColor: "transparent",
                            alignSelf: "start",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "60px",
                            "&:hover": {
                                backgroundColor: "transparent",
                                "& .addRecommenderIcon": {
                                    color: "secondary.addRecommenderButtonHover"
                                },
                                "& .addRecommenderText": {
                                    color: "secondary.addRecommenderButtonHover"
                                },
                                "& .addRecommenderLine": {
                                    backgroundColor: "secondary.addRecommenderButtonHover"
                                }
                            }

                        }}
                        onClick={() => {
                            for (let index = 0; index < recommendersList?.length!; index++) {
                                const recommender = recommendersList?.at(index);
                                if (recommender?.at(0) === null) {
                                    updateRecommendersListAtIndex(new Recomendation("", "", ""), null, index);
                                    setNumRecommenders(numRecommenders + 1);
                                    if (numRecommenders > MAX_RECOMMENDERS) {
                                        setNumRecommenders(MAX_RECOMMENDERS);
                                    }
                                    return;
                                }
                            }
                        }}
                    >
                        {/* Icon and text */}
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center"
                        }}>

                            <Icon
                                sx={{
                                    width: "30px",
                                    height: "30px",
                                    marginRight: "10px",
                                    color: "primary.addRecommenderButton"
                                }}
                                component={PlusIconSVG}
                                className='addRecommenderIcon'
                            />
                            <Typography variant='h3' color={"primary.addRecommenderButton"}
                                        className='addRecommenderText'>
                                הוספת ממליץ
                            </Typography>
                        </Box>
                        {/* Line underneath */}
                        <Box
                            className="addRecommenderLine"
                            sx={{
                                backgroundColor: "primary.addRecommenderButton",
                                height: "3px",
                                borderRadius: "36px",
                                marginTop: "9px",
                                width: "100%"
                            }}
                        />
                    </Button>
                </Box>

            </Box>

        </Box>
    );
}
