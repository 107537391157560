import {SxProps, Theme} from '@mui/material/styles';

export const searchBarStyles = {
    form: {
        display: 'flex',
        mb: 1,
        mt: 2,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        paddingRight: 4,
    },
    iconContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    inputBase: (colorMode: string): SxProps<Theme> => ({
        fontSize: 'large',
        flex: 1,
        ml: 1.5,
        color: colorMode === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2',
        opacity: 1,
        letterSpacing: '0px',
        input: {
            color: colorMode === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2',
            "&::placeholder": {
                opacity: 1,
            },
        },
    }),
    icon: (colorMode: string): SxProps<Theme> => ({
        color: colorMode === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2'
    }),
    underline: (colorMode: string): SxProps<Theme> => ({
        mt: 0,
        backgroundColor: colorMode === 'bright-contrast' ? '#6e86a2' : 'background.JobTitle2',
        height: '1.5px',
        borderRadius: 2,
        opacity: 1,
    }),
};