export const createCustomStyles = (colorMode: any) => ({
    input: (base: any) => ({
        ...base,
        paddingLeft: 4,
        fontSize: 'medium',
        color: colorMode === 'light' ? '#053B7A' :
            colorMode === 'dark-contrast' ? '#b2d0ec' :
                colorMode === 'bright-contrast' ? '#6e86a2' : '#000000',
    }),
    noOptionsMessage: (base: any) => ({
        ...base,
        backgroundColor: colorMode === 'light' ? '#EDEDED' :
            colorMode === 'bright-contrast' ? '#f7f7f7' :
                colorMode === 'dark-contrast' ? '#2d2d2d' : '#EDEDED',
        fontSize: 'small',
    }),
    indicatorSeparator: (baseStyles: any) => ({
        ...baseStyles,
        alignSelf: 'stretch',
        backgroundColor: colorMode === 'light' ? 'hsl(0, 0%, 85%)' :
            colorMode === 'bright-contrast' ? 'hsl(0, 0%, 85%)' :
                colorMode === 'dark-contrast' ? '#424242' : 'hsl(0, 0%, 85%)',
        marginBottom: 8,
        marginTop: 8,
        width: 1,
        borderRadius: 1,
    }),
    control: (baseStyles: any, {menuIsOpen}: { menuIsOpen: boolean }) => ({
        ...baseStyles,
        border: 0,
        boxShadow: 'none',
        backgroundColor: colorMode === 'light' ? '#EDEDED' :
            colorMode === 'bright-contrast' ? '#EDEDED' :
                colorMode === 'dark-contrast' ? '#2d2d2d' : '#EDEDED',
        '&:hover': {cursor: !menuIsOpen && 'pointer'},
    }),
    multiValueLabel: (def: any) => ({
        ...def,
        backgroundColor: colorMode === 'light' ? '#053B7A' :
            colorMode === 'bright-contrast' ? '#6e86a2' :
                colorMode === 'dark-contrast' ? '#b2d0ec' : '#000000',
        color: colorMode === 'light' ? '#FFFFFF' :
            colorMode === 'bright-contrast' ? '#FFFFFF' :
                colorMode === 'dark-contrast' ? '#2d2d2d' : '#FFFFFF',
        borderRadius: 0,
        fontSize: 'small',
    }),
    multiValueRemove: (def: any) => ({
        ...def,
        backgroundColor: colorMode === 'light' ? '#053B7A' :
            colorMode === 'bright-contrast' ? '#6e86a2' :
                colorMode === 'dark-contrast' ? '#b2d0ec' : '#000000',
        color: colorMode === 'light' ? '#FFFFFF' :
            colorMode === 'bright-contrast' ? '#FFFFFF' :
                colorMode === 'dark-contrast' ? '#000000' : '#FFFFFF',
        borderRadius: 0,
        paddingLeft: 2,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: colorMode === 'light' ? '#053B7A' :
                colorMode === 'bright-contrast' ? '#6e86a2' : "#b2d0ec",
        }
    }),
    valueContainer: (base: any) => ({
        ...base,
        minHeight: "38px",
        overflow: 'visible',
    }),
    option: (styles: any, {isSelected, isFocused}: { isSelected: boolean, isFocused: boolean }) => ({
        ...styles,
        color: colorMode === 'dark-contrast' ? (isFocused ? '#000000' : '#FFFFFF') : null,
        backgroundColor: isSelected && !isFocused ? (colorMode === 'dark-contrast' ? '#2d2d2d' : '#FFFFFF') :
            isFocused ? (colorMode === 'dark-contrast' ? '#DEEBFF' : '#DEEBFF') :
                (colorMode === 'dark-contrast' ? '#2d2d2d' : '#FFFFFF'),
        ':active': {
            ...styles[':active'],
            backgroundColor: 'DEEBFF',
        },
    }),
    menu: (def: any) => ({
        ...def,
        zIndex: 9999,
        backgroundColor: colorMode === 'dark-contrast' ? '#2d2d2d' : null,
    }),
    placeholder: (base: any) => ({
        ...base,
        fontSize: 'medium',
        paddingLeft: 4,
        color: colorMode === 'light' ? '#053B7A' :
            colorMode === 'dark-contrast' ? '#b2d0ec' :
                colorMode === 'bright-contrast' ? '#6e86a2' : '#000000',
        fontWeight: 400,
        position: 'absolute'
    }),
});