export const UseStyles = () => ({
    container: {
        flex: {xs: 0, md: 45},
        marginTop: {xs: "102px", md: "0"},
    },
    ellipsesContainer: {
        width: '100%',
        zIndex: "4",
        alignSelf: {xs: "center"}
    },
    yellowEllipse: {
        position: "absolute",
        marginLeft: {xs: "-50px", md: "200px", lg: "250px"},
        marginTop: {xs: "300px", md: "-70px"},
        width: {xs: "171px", md: '133px'},
        height: {xs: "171px", md: '133px'},
        zIndex: "4"
    },
    pinkEllipse: {
        position: "absolute",
        marginTop: {xs: "-70px", md: "200px"},
        marginLeft: {xs: "200px", md: "-70px"},
        width: '133px',
        height: '133px',
        zIndex: "4"
    },
    jobDetailsBox: {
        position: "sticky",
        top: "floatingTop",
        maxHeight: "fit-content",
        paddingRight: "50px",
        paddingBottom: "46px",
        paddingTop: "35px",
        backgroundColor: "background.jobDetails",
        boxShadow: "0px 3px 10px #00000029",
        borderRadius: "10px",
        zIndex: 5,
        width: "fit-content"
    },
    jobDetailsHeader: {
        backgroundColor: "background.JobDetailsText",
        color: "primary.textBright",
        borderRadius: "0px 31px 31px 0px",
        marginBottom: "42px",
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        justifyContent: "start",
        marginLeft: {xs: "-26px", md: "-47px"},
        paddingLeft: {xs: "26px", md: "47px"},
        width: "fit-content"
    },
    jobDetailsHeaderText: {
        textAlign: "left",
        paddingBottom: "10px",
        paddingTop: "9px",
        paddingRight: "33px",
    },
    jobDetailRow: {
        display: "flex",
        flexDirection: "row",
        marginBottom: {xs: "28px", md: "43px"},
        paddingLeft: "55px"
    },
    jobDetailIcon: {
        overflow: 'hidden',
        zIndex: "-1"
    },
    jobDetailIconSvg: {
        height: 19,
        width: 17,
        zIndex: "-1",
        marginRight: "25px",
    },
    jobDetailLabel: {
        color: "secondary.jobDetails"
    },
    jobDetailValue: {
        marginLeft: "10px",
        color: "secondary.descAndReqText"
    },
    jobScopeLabel: {
        minWidth: "50px"
    },
    jobStartLabel: {
        minWidth: {xs: "100px", md: "126px"}
    }
});