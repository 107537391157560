export const boxStyles = {
    display: { xs: "none", md: "none", lg: "block" },
    position: "absolute",
    left: { lg: "20px", xl: "1.5vw" },
    marginTop: "-60px"
};

export const buttonStyles = {
    paddingTop: "13px",
    paddingBottom: "13px",
    paddingLeft: "28px",
    paddingRight: "28px",
    backgroundColor: "background.cvButton",
    "&:hover": {
        backgroundColor: "background.cvButtonHover"
    },
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "36px"
};

export const typographyStyles = {
    display: { lg: "none", xl: "block" }
};

export const iconStyles = {
    height: "30px",
    width: "40px",
    color: "primary.textBright",
    marginLeft: { xs: "2px", md: "0px" }
};