import React from "react";
import {ColorModeContext} from "../../Theme/Theme";
import {Box, Stack, Typography, IconButton, Collapse, Divider} from '@mui/material';
import {Link} from 'react-router-dom';
import Logo from '../../Assets/logo.png';
import colors from "../../Theme/LayoutTheme";
import * as styles from "./NavbarStyles";

export default function Navbar() {
    const colorMode = React.useContext(ColorModeContext);
    const [open, setOpen] = React.useState(false);
    const theme = colors(colorMode?.getActualMode());

    return (
        <Box sx={styles.container(theme)}>
            <Stack direction='row' justifyContent='space-between' sx={styles.container2nd(theme)}>
                <Stack direction='row' id='desk' sx={styles.container3rd()} spacing={{xs: 5, md: 10}}
                       justifyContent='start' display={{xs: 'none', md: 'flex'}}>
                    <Box id="logo_desktop" display={'flex'} flexDirection={'row'} sx={styles.logoContainer()}>
                        <img src={Logo} alt="Logo" style={styles.logoImage(theme)}/>
                    </Box>

                    <Stack direction='column' justifyContent='center' display={{xs: 'none', md: 'flex'}}>
                        <Stack direction='row' spacing={4} sx={styles.desktopNav}>
                            <Box id="generalPage" sx={styles.desktopNavItem}>
                                <Box sx={styles.desktopNavBox}>
                                    <Link style={styles.desktopNavLink} to={`https://www.gvanim.org.il/`}>
                                        <Box sx={styles.navLinkContent}>
                                            <Typography variant='body2' sx={styles.navLinkText(theme)}>
                                                ראשי
                                            </Typography>
                                        </Box>
                                        <Box id='borderBottom' sx={styles.navLinkBorder(theme)}/>
                                    </Link>
                                </Box>
                            </Box>

                            <Box id="jobsPage" sx={styles.desktopNavItem}>
                                <Box sx={styles.desktopNavBox}>
                                    <Link style={styles.desktopNavLink} to={`/career/jobs`}>
                                        <Box sx={styles.navLinkContent}>
                                            <Typography variant='body2' sx={styles.navLinkText(theme)}>
                                                משרות
                                            </Typography>
                                        </Box>
                                        <Box id="borderBottom" sx={styles.navLinkBorder(theme)}/>
                                    </Link>
                                </Box>
                            </Box>

                            <Box id="aboutUsPage" sx={styles.desktopNavItem}>
                                <Box sx={styles.desktopNavBox}>
                                    <Link style={styles.desktopNavLink}
                                          to={`https://www.gvanim.org.il/index.php/he/node/4`}>
                                        <Box sx={styles.navLinkContent}>
                                            <Typography variant='body2' sx={styles.navLinkText(theme)}>
                                                אודות
                                            </Typography>
                                        </Box>
                                        <Box id="borderBottom" sx={styles.navLinkBorder(theme)}/>
                                    </Link>
                                </Box>
                            </Box>
                        </Stack>
                    </Stack>
                </Stack>

                <Stack direction='column' justifyContent='center'>
                    <Stack direction='row' spacing={2.5} display={{xs: 'none', md: 'flex'}}>
                        <Box>
                            <Typography variant='caption' sx={styles.helpText(theme)}>
                                צריכים עזרה? דברו איתנו
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant='caption' sx={styles.phoneNumber(theme)}>
                                9913*
                            </Typography>
                        </Box>
                    </Stack>
                </Stack>

                <Stack direction='row' id='mobile' sx={styles.mobileNav} justifyContent='space-between' display={{xs: 'flex', md: 'none'}}>
                    <Box sx={styles.mobileMenuButton}>
                        <IconButton disableRipple onClick={() => setOpen(!open)}>
                            <Stack sx={styles.hamburgerStack}>
                                <Box sx={styles.hamburgerLineTop(open, theme)}/>
                                <Box sx={styles.hamburgerLineMiddle(open, theme)}/>
                                <Box sx={styles.hamburgerLineBottom(open, theme)}/>
                            </Stack>
                        </IconButton>
                    </Box>

                    <Box sx={styles.mobileLogoContainer}>
                        <img src={Logo} alt="Logo" style={styles.logoImage(theme)}/>
                    </Box>

                    <Box sx={styles.mobilePhoneContainer}>
                        <Typography variant='caption' sx={styles.phoneNumber(theme)}>
                            9913*
                        </Typography>
                    </Box>
                </Stack>
            </Stack>

            <Stack sx={styles.mobileMenuContainer(theme)}>
                <Collapse in={open}>
                    <Stack sx={styles.mobileMenuStack} divider={<Divider sx={{backgroundColor: 'primary.divider'}}/>}>
                        <Stack direction='row' justifyContent='center' width='100%'>
                            <Link style={styles.mobileMenuLink} to={`https://www.gvanim.org.il/`}
                                  onClick={() => setOpen(false)}>
                                <Box>
                                    <Typography sx={styles.mobileMenuText(theme)}>
                                        ראשי
                                    </Typography>
                                    <Box sx={styles.mobileMenuBorder(theme)}/>
                                </Box>
                            </Link>
                        </Stack>

                        <Stack direction='row' justifyContent='center' width='100%'>
                            <Link style={styles.mobileMenuLink} to={`/career/jobs`} onClick={() => setOpen(false)}>
                                <Box>
                                    <Typography sx={styles.mobileMenuText(theme)}>
                                        משרות
                                    </Typography>
                                    <Box sx={styles.mobileMenuBorder(theme)}/>
                                </Box>
                            </Link>
                        </Stack>

                        <Stack direction='row' justifyContent='center' width='100%'>
                            <Link style={styles.mobileMenuLink} to={`https://www.gvanim.org.il/index.php/he/node/4`}
                                  onClick={() => setOpen(false)}>
                                <Box>
                                    <Typography sx={styles.mobileMenuText(theme)}>
                                        אודות
                                    </Typography>
                                    <Box sx={styles.mobileMenuBorder(theme)}/>
                                </Box>
                            </Link>
                        </Stack>
                    </Stack>
                </Collapse>
            </Stack>
        </Box>
    );
}