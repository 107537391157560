export const containerStyles = (theme) => ({
    WebkitFilter: theme.grayscale,
    filter: theme.grayscale
});

export const logoContainerStyles = (theme) => ({
    filter: theme.bottom_image,
    width: "100%",
    display: "flex",
    justifyContent: "center"
});

export const logoStyles = () => ({
    pointerEvents: "none"
});

export const contactInfoStyles = () => ({
    height: "fit-content"
});