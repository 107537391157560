export const box = () => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
});

export const dialogTitle = () => ({
    color: 'primary.descAndReqTitle',
});

export const iconButton = () => ({
    margin: "5px",
    color: "primary.descAndReqTitle"
});

export const dialogActions = () => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
});