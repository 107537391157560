import {SxProps, Theme} from "@mui/material/styles";

export default function useStyles(colorMode) {
    const arrowIcon: SxProps<Theme> = {
        color:
            colorMode === 'bright-contrast'
                ? '#6e86a2'
                : 'background.JobTitle2',
        '&:hover': {cursor: 'pointer'},
    };

    return {
        arrowIcon,
    };
};