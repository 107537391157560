export const container = () => ({
    padding: '16px',
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
});

export const box = () => ({
    display: 'flex',
    justifyContent: 'center',
});

export const stack = () => ({
    paddingTop: '32px',
    paddingBottom: '32px',
    flexDirection: 'column',
    width: '100%',
    color: 'primary.myBoxShadow'
});


export const title = () => ({
    textAlign: 'center',
    color: 'primary.drushimTitle',
});

export const bodyText = () => ({
    textAlign: 'center',
    color: 'secondary.drushimTitle'
});