import React from 'react';
import {Box, Backdrop, Avatar, Stack, Typography} from '@mui/material';
import Logo from "../../Assets/logo.png";
import "./GvanimProgress.css";

interface GvanimProgressProps {
    loading: boolean;
    showWaiting?: boolean;
}

export default function GvanimProgress(props: GvanimProgressProps) {
    const {loading, showWaiting = false} = props;

    return (
        <Box sx={{
            display: 'flex',
            width: '100%',
            height: '100dvh',
            justifyContent: 'center',
            alignItems: 'center'
        }}> {/* Added alignItems */}
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: 999,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }} // Added flex properties
                open={loading}
            >
                <Stack direction="column" spacing={3} alignItems="center"> {/* Added Stack for layout */}
                    <Avatar variant='circular' className="rotate_03" src={Logo} sx={{
                        border: '1px solid #dee2e6', width: 90, height: 90,
                        '& .MuiAvatar-img': {
                            objectFit: 'contain',
                        }
                    }}/>

                    {showWaiting && (
                        <Stack direction='column' spacing={1}
                               alignItems="center"> {/* Removed paddingTop, added alignItems */}
                            <Typography color='primary.drushimTitle' variant='h2' textAlign='center'>
                                הנתונים נקלטים במערכת
                            </Typography>

                            <Typography variant='body2' color='secondary.drushimTitle' textAlign='center'>
                                לתשומת ליבך, פעולה זו עלולה לקחת מס' דקות.
                            </Typography>
                        </Stack>
                    )}
                </Stack>
            </Backdrop>
        </Box>
    );
}