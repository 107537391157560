import React from "react";
import {
    containerStyles,
    logoBoxStyles,
    logoImageStyles,
    stackStyles, subtitleTypographyStyles,
    titleTypographyStyles
} from "./NotFoundPageStyles";
import {Box, Container, Stack, Typography} from "@mui/material";
import Logo from "../../Assets/logo.png";

export default function PageNotFound() {
    return (
        <Container maxWidth="md" sx={containerStyles}>
            <Box display="flex" justifyContent="center">
                <Stack sx={stackStyles}>
                    <Box sx={logoBoxStyles}>
                        <img src={Logo} alt="Logo" style={logoImageStyles}/>
                    </Box>
                    <Stack direction="column" spacing={1}>
                        <Typography variant="h2" sx={titleTypographyStyles}>
                            אנו מתנצלים,
                        </Typography>
                        <Typography variant="body2" sx={subtitleTypographyStyles}>
                            העמוד אינו נמצא...
                        </Typography>
                    </Stack>
                </Stack>
            </Box>
        </Container>
    );
}