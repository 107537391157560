import React from "react";
import {OptionType} from "../../../../../Components/CustomMultiSelect/Option.type";
import {getFilteredJobs} from "../../../../../../Firebase/FirebaseFunctions/Job";
import MultiSelect from "../../../../../Components/CustomMultiSelect/CustomMultiSelect";

export default function LocationMultiSelect(props: { optionSelected: any, setSelected: any }) {
    const {optionSelected, setSelected} = props;
    const [locations, setLocations] = React.useState<OptionType[]>([]);

    const handleChange = (selected: OptionType[]) => setSelected(selected);
    const fetchLocations = async () => {
        const jobs = await getFilteredJobs();
        const uniqueLocations = new Set<string>();
        jobs.forEach(job => {
            if (job._region) {
                const locations = job._region.split(',').map(loc => loc.trim()).filter(Boolean);
                locations.forEach(location => uniqueLocations.add(location));
            }
        });

        return Array.from(uniqueLocations)
            .sort()
            .map((location, index) => ({value: index, label: location}));
    }

    React.useEffect(() => {
        fetchLocations().then((locs) => setLocations(locs));
    }, []);

    return (
        <>
            <MultiSelect
                options={locations}
                onChange={handleChange}
                value={optionSelected}
                isSelectAll={true}
                menuPlacement={"bottom"}
            />
        </>
    );
}
