function colorsLight() {
    return {
        gray: '#00000029',
        white: '#FFFFFF',
        main: '#053B7A',
        body: '#053B7A',
        underline: '#91A749',
        caption: '#5BA1AA',
    };
}

function colorsDarkContrast() {
    return {
        gray: 'primary.divider',
        white: '#000000',
        main: '#b2d0ec',
        body: '#b2d0ec',
        underline: '#c3c6aa',
        caption: '#B2D0EC',
    }
}

function colorsBrightContrast() {
    return {
        gray: '#00000029',
        white: '#FFFFFF',
        main: '#a9d1ff',
        body: '#053B7A',
        underline: '#91a749',
        caption: '#5BA1AA',
    };
}

function colorsBlackWhite() {
    return {
        gray: '#00000029',
        white: '#FFFFFF',
        main: '#000000',
        body: '#000000',
        underline: 'darkgray',
        caption: '#000000'
    }
}

function getWebkitFilterGrayscale(mode) {
    return (mode !== 'light' && mode !== 'dark-contrast' && mode !== 'bright-contrast') ? 'grayscale(1)' : 'grayscale(0)';
}

function getFilterBrightness(mode) {
    return mode === 'bright-contrast' ? 'brightness(0.7)' : 'brightness(1)';
}

function getFilterGrayscale(mode) {
    if (mode !== 'light' && mode !== 'dark-contrast' && mode !== 'bright-contrast') {
        return 'grayscale(1)';
    }

    return getFilterBrightness(mode);
}

function getFilterBottomImage(mode) {
    if (mode !== 'light' && mode !== 'dark-contrast' && mode !== 'bright-contrast') {
        return 'grayscale(1)';
    }

    if (mode === 'bright-contrast') {
        return 'brightness(0.5)';
    }

    if (mode === 'dark-contrast') {
        return 'brightness(0.8)';
    }

    return 'grayscale(0)';
}

export default function colors(mode) {
    let theme;

    if (mode === 'light') {
        theme = colorsLight();
    } else if (mode === 'dark-contrast') {
        theme = colorsDarkContrast();
    } else if (mode === 'bright-contrast') {
        theme = colorsBrightContrast();
    } else {
        theme = colorsBlackWhite();
    }

    return {
        ...theme,
        webkit_filter: getWebkitFilterGrayscale(mode),
        bottom_image: getFilterBottomImage(mode),
        grayscale: getFilterGrayscale(mode),
        brightness: getFilterBrightness(mode)
    }
}
