import { SxProps, Theme } from "@mui/material";

export const useScrollTopStyles = (theme) => {
    const fadeStyle: React.CSSProperties = {
        left: 20,
        filter:
            theme === 'bright-contrast'
                ? 'contrast(50%)'
                : theme !== 'light' && theme !== 'dark-contrast'
                    ? 'grayscale(1)'
                    : 'grayscale(0)'
    };

    const floatingButtonStyle: SxProps<Theme> = {
        position: 'fixed',
        zIndex: 100,
        width: 'fit-content',
        right: 'auto',
        left: { sm: 'auto', md: 0, lg: '12%' },
        padding: { lg: 1 },
        paddingLeft: { xs: 1, sm: 0, md: 1 },
        bottom: { xs: '20px', md: '120px', lg: '310px', xl: '370px' }
    };

    const fabStyle: SxProps<Theme> = {
        backgroundColor: {
            xs: 'secondary.jobDetails',
            md:
                theme === 'dark-contrast'
                    ? '#7aa3c2'
                    : '#3b82f680'
        },
        ':hover': {
            backgroundColor: {
                xs: theme === 'bright-contrast' ? '#38212C' : '#BC5887',
                md: '#99CCF3'
            },
        },
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    };

    const arrowStyle: SxProps<Theme> = {
        color: {
            xs: '#FFFFFF',
            md: '#004C99'
        },
    };

    return {
        fadeStyle,
        floatingButtonStyle,
        fabStyle,
        arrowStyle
    };
};