
export const background = () => ({
    height: '100%',
    width: '100%',
    position: 'absolute',
    overflow: 'hidden',
    zIndex: "-1"
});

export const backgroundIcon = () => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "stretch",
    marginLeft: {xs: "25px", xl: "18.75vw", lg: "12vw"},
    marginRight: {xs: "25px", xl: "18.75vw", lg: "12vw"},
    paddingTop: "136px"
});

export const page = () => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "stretch",
    marginLeft: {xs: "25px", xl: "18.75vw", lg: "12vw"},
    marginRight: {xs: "25px", xl: "18.75vw", lg: "12vw"},
    paddingTop: "136px",
});