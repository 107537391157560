import {Box, Container, Stack, Typography} from "@mui/material";
import React from "react";
import "./ThanksPage.css";
import {bodyText, box, container, stack, title} from "./ThanksPageStyles";

export default function ThanksPage() {
    const [homePage, setHomePage] = React.useState<string>('');

    React.useEffect(() => {
        setHomePage('../career/jobs');
    }, [])

    return (
        <Container maxWidth='md' sx={container()}>
            <Box sx={box()}>
                <Stack sx={stack()}>
                    <Typography sx={title()} variant='h2'>
                        מועמדותך נקלטה בהצלחה!
                    </Typography>

                    <Typography sx={bodyText()} variant='body2'>
                        {"לחזרה לדף המשרות "}
                        <a className='a' target='_self' href={`${homePage}`}>
                            לחץ כאן
                        </a>.
                    </Typography>
                </Stack>

            </Box>
        </Container>
    )
}