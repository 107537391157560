import {useNavigate, useParams} from "react-router-dom";
import React from "react";
import GvanimProgress from "../../Components/GvanimProgress/GvanimProgress";
import JobInformation from "./Components/JobInfo/JobInfo";
import {getFilteredJobs, Job} from "../../../Firebase/FirebaseFunctions/Job";
import {Box, Icon} from "@mui/material";
import {UseScreenSelectors} from "../../../Utilities/ScreenSize";
import {ReactComponent as BackgroundSVG} from "../../Assets/Background.svg";
import MobileBackground from "../../Assets/MobileBackground.png";
import ApplicationForm from "./Components/ApplicationForm/ApplicationForm";
import {background, backgroundIcon, page} from "./JobPageStyles";

export default function JobPage() {
    const {id} = useParams();
    const [loading, setLoading] = React.useState(false);
    const [job, setJob] = React.useState<Job | null>(null);

    const jobApplication = React.useRef<HTMLParagraphElement>(null);

    const navigate = useNavigate();
    const screenSize = UseScreenSelectors();

    React.useEffect(() => {
        setLoading(true);

        getFilteredJobs(["jobNumber"], [id!])
            .then((jobData) => {
                const data = jobData[0];
                if (!data || !data._open) {
                    navigate('/career/not-found');
                } else {
                    setJob(data);
                }
            })
            .finally(() => setLoading(false))
    }, [id]);

    if (loading) {
        return <GvanimProgress loading={loading}/>;
    }

    return (<>
        {/* Background */}
        <Box sx={background()}>
            {screenSize === "xs" ? (
                <img
                    src={MobileBackground}
                    height="408px"
                    width="100%"
                />
            ) : (
                <Icon sx={backgroundIcon()} component={BackgroundSVG}/>
            )}
        </Box>
        {/* Entire Page */}
        <Box sx={page()}>
            <JobInformation job={job!} jobApplicationElement={jobApplication}/>
            <ApplicationForm jobApplicationElement={jobApplication} jobNumber={job?._jobNumber!}/>
        </Box>
    </>);
}
