import {styled} from "@mui/system";
import {Button, Drawer} from "@mui/material";

export const DEFAULT_FONT_FAMILY = "Rubik";
export const READABLE_FONT_FAMILY = "Arial";
export const DEFAULT_FONT_SIZE = 20

export const StyledButton = styled(Button)`
    transition: transform 0.3s ease-in-out;

    &:hover {
        transform: translateX(-10px);
    }

    &:not(:hover) {
        transform: translateX(0);
    }
`;

export const CustomDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paperAnchorLeft': {
        transform: 'translateX(100%)',
        transition: 'transform 0.3s !important',
        left: "calc(100% - 250px)",
        zIndex: "12000"
    }
}));
